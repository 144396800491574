import React, { Component } from "react";
import { Breadcrumbs, LabelBar, Table, Field } from "components/index";
import { Row, Button, Modal, Image, Col, Form, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { POST } from "../../../../api/api";
import XlsExport from "xlsexport";
import dayjs from "dayjs";
import { getToken } from "../../../../static/function";

export default class Detail extends Component {
     constructor(props) {
          super(props);
          this.state = {
               id: this.props.match.params.id,
               logs: [],
               supplier: {},
               previewInfo: false,
               oldInfo: {},
               show: false,
               dataEditItem: [],
               file: {},
               fileTextHolder: "",
               loading: false,
          };
     }

     componentDidMount = () => {
          this.getSupplier();
          this.getLogs();
     };

     getSupplier = async () => {
          try {
               let getsupplier = await POST("/ps/this_supplier", {
                    supplier_id: this.props.match.params.id,
               });
               // console.log("getsupplier", getsupplier);
               this.setState({
                    supplier: { ...getsupplier },
                    oldInfo: { ...getsupplier },
                    dataEditItem: getsupplier.data_items,
               });
          } catch (error) {
               console.log(error);
          }
     };

     getLogs = async () => {
          try {
               this.setState({
                    logs: [
                         ...(await POST("/ps/this_log", {
                              id_do: this.props.match.params.id,
                              log_type: "supplier",
                         })),
                    ],
               });
          } catch (error) {
               console.log(error);
          }
     };

     /* Remove insurance */
     handleClickRemove = async () => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการลบ",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         let { id } = this.props.match.params;
                         await POST("/aos/del_sup", { id });
                         swal({
                              text: "ลบเรียบร้อบแล้ว",
                              icon: "success",
                              button: "เสร็จสิ้น",
                         }).then(() => {
                              window.location.assign(`/admin/supplier/list`);
                         });
                    }
               });
          } catch (error) {}
     };

     handleClick = () => {
          let { id } = this.state;
          window.location.assign(`/admin/supplier/detail/edit/${id}`);
     };

     previewEdit = (row) => {
          let { supplier, oldInfo } = this.state;
          supplier = { ...row };
          supplier.nameuser_create = oldInfo.nameuser_create;
          this.setState({ previewInfo: true, supplier });
     };

     setOldInfo = () => {
          this.setState({
               supplier: { ...this.state.oldInfo, previewInfo: false },
               previewInfo: false,
          });
     };

     /* Formatter */
     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          return row.log_do === "แก้ไข" ? <Button onClick={() => this.previewEdit(row)}>ดูการแก้ไข</Button> : <></>;
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     };

     handleClose = (value) => {
          if (value) {
               this.setState({ show: !this.state.show });
          }
     };
     downloadItems = async () => {
          try {
               let supplier_id = this.props.match.params.id;
               let { supplier } = this.state;
               let res = await POST("/aos/download_items", { supplier_id });
               // console.log('res', res)
               if (res.length > 0) {
                    const xls = new XlsExport(res, "ข้อมูลอุปกรณ์ " + supplier.supplier_name);
                    xls.exportToXLS("ข้อมูลอุปกรณ์ " + supplier.supplier_name + "(" + dayjs(new Date()).format("DD-MM-YYYY") + ")" + ".xls");
               } else {
                    swal({
                         text: "ไม่มีอุปกรณ์",
                         icon: "warning",
                         button: "ตกลง",
                    });
               }
          } catch (error) {}
     };
     handleChangeFile = (event) => {
          if (event.target.files[0]) {
               const fileUploaded = event.target.files[0];
               this.setState({
                    file: fileUploaded,
                    fileTextHolder: fileUploaded.name,
               });
          }
     };
     uploadItems = async () => {
          try {
               const { file } = this.state;
               try {
                    if (file) {
                         this.setState({ loading: true });
                         let formData = new FormData();
                         formData.append("type", "update_items");
                         formData.append("file", file);
                         await POST("/aos/" + "update_items", formData, true);
                         swal({
                              text: "เสร็จสิ้น",
                              icon: "success",
                              button: "ตกลง",
                         }).then(() => window.location.reload());
                    }
               } catch (error) {
                    swal({
                         text: "กรุณาอัปโหลดให้ถูกประเภท",
                         icon: "warning",
                         button: "ตกลง",
                         dangerMode: true,
                    }).then(() => window.location.reload());
               }
          } catch (error) {}
     };
     testMail = async () => {
          try {
               let { supplier } = this.state;
               if (supplier.supplier_mail && supplier.supplier_mail !== "null") {
                    let res = await POST("/aos/test_mail", { mail: supplier.supplier_mail });
                    console.log("res", res);
               } else {
                    swal({ icon: "warning", text: "กรุณากรอก E-mail ก่อนทำรายการ" });
               }
          } catch (error) {}
     };
     render() {
          let { logs, supplier, previewInfo, show, dataEditItem, fileTextHolder, loading } = this.state;
          const column = [
               {
                    dataField: "log_do",
                    text: "การดำเนินการ",
                    headerClasses: "header-custom __left __btn-s",
               },
               {
                    dataField: "updated_at",
                    text: "วันที่ดำเนินการ",
                    formatter: this.dateFormatter,
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "log_status",
                    text: "สถานะ",
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "nameuser_update",
                    text: "ผู้ดำเนินการ",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "detail",
                    text: "ดูการแก้ไข",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __right __btn-s",
               },
          ];
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: "Supplier",
                                   onClick: () => this.props.history.push("/admin/supplier/list"),
                              },
                              { title: "ข้อมูล" },
                         ]}
                         active={1}
                         button={[
                              {
                                   name: "แก้ไขข้อมูล",
                                   icon: "fas fa-pen-square",
                                   handleClick: this.handleClick,
                                   variant: "primary",
                              },
                              {
                                   name: "ลบข้อมูล",
                                   icon: "fas fa-trash alt",
                                   handleClick: this.handleClickRemove,
                                   variant: "danger",
                              },
                         ]}
                    />

                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        {/* ข้อมูลการสร้าง */}
                                        <div>
                                             <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                                             {previewInfo === true && <Button onClick={this.setOldInfo}>ดูข้อมูลเดิม</Button>}
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "User Create : ",
                                                            detail: supplier?.nameuser_create,
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "User Update ล่าสุด :",
                                                            detail: supplier?.nameuser_update,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ Create : ",
                                                            detail: supplier?.created_at ? this.dateFormatter(supplier?.created_at) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ Update ล่าสุด : ",
                                                            detail: supplier?.update_at ? this.dateFormatter(supplier?.update_at) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "การอนุมัติ : ",
                                                            detail: supplier?.acept_status,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        {/* ข้อมูล Supplier  */}
                                        <div>
                                             <LabelBar type={"title"} title={"ข้อมูล Supplier"} />
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Supplier Code : ",
                                                            detail: supplier.supplier_code
                                                                 ? supplier.supplier_code === "null"
                                                                      ? "-"
                                                                      : supplier.supplier_code
                                                                 : "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Supplier Name : ",
                                                            detail: supplier?.supplier_name,
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Supplier Name (สคบ.) : ",
                                                            detail: supplier?.name_reserve || "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขประจำตัวผู้เสียภาษี : ",
                                                            detail: supplier.supplier_tax
                                                                 ? supplier.supplier_tax === "null"
                                                                      ? "-"
                                                                      : supplier.supplier_tax
                                                                 : "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  {supplier.supplier_type && supplier.supplier_type == "ประกัน" && (
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "เบี้ยประกัน : ",
                                                                 detail: Number(supplier?.insurance_premium).toLocaleString(undefined, {
                                                                      minimumFractionDigits: 2,
                                                                      maximumFractionDigits: 2,
                                                                 }),
                                                            }}
                                                            md={12}
                                                       />
                                                  )}
                                             </Row>
                                             {/* {supplier.supplier_type &&
                                                  supplier.supplier_type !== "ประกัน" &&
                                                  supplier.supplier_type !== "Leasing" && ( */}
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อผู้ติดต่อ : ",
                                                            detail: supplier?.supplier_person
                                                                 ? supplier?.supplier_person === "null"
                                                                      ? "-"
                                                                      : supplier?.supplier_person
                                                                 : "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขโทรศัพท์ : ",
                                                            detail: supplier?.supplier_tel
                                                                 ? supplier?.supplier_tel === "null"
                                                                      ? "-"
                                                                      : supplier?.supplier_tel
                                                                 : "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Email : ",
                                                            detail: supplier?.supplier_mail
                                                                 ? supplier?.supplier_mail === "null"
                                                                      ? "-"
                                                                      : supplier?.supplier_mail
                                                                 : "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ID LINE : ",
                                                            detail: supplier?.supplier_line
                                                                 ? supplier?.supplier_line === "null"
                                                                      ? "-"
                                                                      : supplier?.supplier_line
                                                                 : "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อบัญชี : ",
                                                            detail: supplier?.supplier_accountname || "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ธนาคาร : ",
                                                            detail: supplier?.supplier_bank || "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขบัญชีธนาคาร : ",
                                                            detail: supplier?.supplier_bookbank || "-",
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                             {/* )} */}
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ : ",
                                                            detail: `${supplier?.supplier_address}`,
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สถานะ : ",
                                                            detail: supplier?.status,
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                             <Row className="d-flex justify-content-center">
                                                  <Col xs={12} sm={4}>
                                                       <Button style={{ background: "green" }} onClick={this.testMail}>
                                                            ทดสอบ E-mail
                                                       </Button>
                                                  </Col>
                                             </Row>
                                        </div>
                                        {/* Payment Term */}
                                        <div>
                                             <LabelBar type={"title"} title={"Payment Term"} />
                                             <Row onClick={() => this.handleClose(supplier?.supplier_file_name)}>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Book Bank :",
                                                            detail: supplier.supplier_file_name
                                                                 ? supplier.supplier_file_name === "null"
                                                                      ? "-"
                                                                      : supplier.supplier_file_name
                                                                 : "-",
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                        </div>
                                        {supplier && (supplier.supplier_type === "ประกัน" || supplier.supplier_type === "Leasing") ? (
                                             <div className="mb-3">
                                                  <LabelBar type={"title"} title={"รายการ Supplier"} />
                                                  <Row>
                                                       {dataEditItem.map((el, i) => (
                                                            <Col xs={12} key={i}>
                                                                 <Form.Row>
                                                                      <Field
                                                                           type="text"
                                                                           title="ชื่อตัวแทน"
                                                                           name="items_name"
                                                                           placeholder="ชื่อตัวแทน"
                                                                           value={el.items_name}
                                                                           md={3}
                                                                           handleChangeText={(e) => this.handleChangeText(e, i)}
                                                                      />
                                                                      <Field
                                                                           type="text"
                                                                           title="เบอร์โทร"
                                                                           name="items_phone"
                                                                           placeholder="เบอร์โทร"
                                                                           value={el.items_phone}
                                                                           md={3}
                                                                           handleChangeText={(e) => this.handleChangeText(e, i)}
                                                                      />
                                                                      <Field
                                                                           type="text"
                                                                           title="Line ID"
                                                                           name="items_line"
                                                                           placeholder="Line ID"
                                                                           value={el.items_line}
                                                                           md={3}
                                                                           handleChangeText={(e) => this.handleChangeText(e, i)}
                                                                      />
                                                                      <Field
                                                                           type="text"
                                                                           title="Email"
                                                                           name="items_mail"
                                                                           placeholder="Email"
                                                                           value={el.items_mail}
                                                                           md={3}
                                                                           handleChangeText={(e) => this.handleChangeText(e, i)}
                                                                      />
                                                                 </Form.Row>
                                                            </Col>
                                                       ))}
                                                       {dataEditItem.length ? (
                                                            <Col xs={12} className="text-center">
                                                                 <Button style={{ width: 250 }} onClick={this.editItems}>
                                                                      บันทึกการแก้ไข
                                                                 </Button>
                                                            </Col>
                                                       ) : null}
                                                  </Row>
                                             </div>
                                        ) : null}

                                        <div>
                                             <LabelBar type={"title"} title={"ประวัติการแก้ไข"} />
                                             <div className="content" id="table-content">
                                                  <Table type={"custom"} keyField={"date"} column={column} data={logs} />
                                             </div>
                                        </div>
                                   </div>
                                   {getToken().position_id === 13 || getToken().position_id === 25 ? (
                                        <div className="content">
                                             <div>
                                                  <LabelBar type={"title"} title={"ดาวน์โหลดอุปกรณ์"} />
                                                  <Form.Row>
                                                       <Field
                                                            type={"file"}
                                                            title={{
                                                                 name: "File CSV.",
                                                                 icon: null,
                                                            }}
                                                            require={false}
                                                            md={6}
                                                            placeholder={fileTextHolder ? fileTextHolder : "File CSV."}
                                                            button={"แนบไฟล์"}
                                                            style_id={"custom-btn __beside-btn"}
                                                            handleChange={this.handleChangeFile}
                                                       />
                                                  </Form.Row>
                                             </div>
                                             <Row className="mt-5 mb-5">
                                                  {loading ? (
                                                       <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                            Loading...
                                                       </Button>
                                                  ) : (
                                                       <>
                                                            <Col xs={12} sm={6}>
                                                                 <Button onClick={this.downloadItems}>ดาวน์โหลดอุปกรณ์</Button>
                                                            </Col>
                                                            <Col xs={12} sm={6}>
                                                                 <Button style={{ background: "green" }} onClick={this.uploadItems}>
                                                                      อัพโหลดอุปกรณ์
                                                                 </Button>
                                                            </Col>
                                                       </>
                                                  )}
                                             </Row>
                                        </div>
                                   ) : null}
                              </div>
                         </div>
                    </div>
                    <Modal show={show} onHide={this.handleClose}>
                         <Image style={{ width: "500px" }} src={supplier?.supplier_file} />
                    </Modal>
               </div>
          );
     }
     handleChangeText = (e, i) => {
          // console.log("e", e.target);
          let { name, value } = e.target;
          let { dataEditItem } = this.state;
          dataEditItem[i][name] = value;
          this.setState({ dataEditItem });
     };
     editItems = async () => {
          try {
               let data = this.state.dataEditItem;
               await POST("/aos/edit_item_by_sup", { data });
               swal({
                    text: "แก้ไขรายการเรียบร้อย",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => {
                    window.location.reload();
               });
          } catch (error) {
               swal({
                    text: "แก้ไขรายการไม่ได้",
                    icon: "warning",
                    button: "ลองใหม่",
               });
          }
     };
}
