import React, { Component } from "react";
import { Breadcrumbs, LabelBar } from "components/index";
import { Row, Col, Button, Modal, Image } from "react-bootstrap";
import swal from "sweetalert";
import { POST } from "api";
import dayjs from "dayjs";

function dateFormatter(cell, row, rowIndex, formatExtraData) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class Approve extends Component {
     constructor(props) {
          super(props);
          this.state = {
               file: [],
               id: this.props.match.params.id,
               supplier: {},
               oldSupplier: {},
               title: [],
               show: false,
          };
     }
     /* Approve insurance */
     handleApprove = async (status) => {
          try {
               let { supplier, id } = this.state;
               await POST("/ps/confirm_edit_supplier", {
                    ...supplier,
                    supplier_id: supplier.id_do,
                    acept_log_status: status,
                    log_id: id,
                    edit: supplier.log_do === "เพิ่ม" ? false : true,
               });

               swal({
                    text: "อนุมัติเรียบร้อบแล้ว",
                    icon: "success",
                    button: "เสร็จสิ้น",
               }).then(() => this.props.history.push("/admin/supplier/setting"));
          } catch (error) {
               swal({
                    text: error,
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
          }
     };

     handleClick = () => {
          let { id } = this.state;
          window.location.assign(`/admin/supplier/detail/edit/${id}`);
     };

     openLink = () => {
          this.setState({ show: !this.state.show });
     };

     /* Formatter */
     detailFormatter() {
          return <Button>ดูเอกสาร</Button>;
     }

     componentDidMount = () => {
          // console.log(this.props.match.params);
          this.getSupplier();
          this.setPath();
     };

     getSupplier = async () => {
          try {
               let getsupplier = await POST("/ps/log_detail", { log_id: this.state.id });
               let oldSupplier = await POST("/ps/this_supplier", {
                    supplier_id: getsupplier.id_do,
               });

               this.setState({
                    supplier: { ...getsupplier },
                    oldSupplier: { ...oldSupplier },
               });
          } catch (error) {}
     };

     setPath = () => {
          let { manage } = this.props.match.params;
          // console.log(manage);
          let { title } = this.state;

          title = [
               {
                    title: "การอนุมัติ เพิ่ม/ลบ/แก้ไข Supplier",
                    onClick: () => this.props.history.goBack(),
               },
               {
                    title: "ทำรายการ (Supplier Name)",
                    // onClick: () => this.props.history.goBack(),
               },
          ];

          this.setState({ title: title });
     };

     openFile = (fileUrl) => {
          window.open(fileUrl);
     };

     render() {
          let { supplier, oldSupplier, title, show } = this.state;

          return (
               <div className="panel">
                    <Breadcrumbs title={title} active={1} button={""} />

                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-header">
                                   <div>ข้อมูลปัจจุบัน</div>
                              </div>
                              <div className="-body">
                                   <div className="content">
                                        {/* ข้อมูลการสร้าง */}
                                        <div>
                                             <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "User Create : ",
                                                            detail: oldSupplier?.nameuser_create,
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "User Update ล่าสุด :",
                                                            detail: oldSupplier?.nameuser_update,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ Create : ",
                                                            detail: oldSupplier?.created_at ? dateFormatter(oldSupplier?.created_at) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ Update ล่าสุด : ",
                                                            detail: oldSupplier?.updated_at ? dateFormatter(oldSupplier?.updated_at) : "-",
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "การอนุมัติ : ",
                                                            detail: oldSupplier?.acept_status,
                                                       }}
                                                       md={6}
                                                  />
                                             </Row>
                                        </div>
                                        {/* ข้อมูล Supplier  */}
                                        <div>
                                             <LabelBar type={"title"} title={"ข้อมูล Supplier"} />
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Supplier Code : ",
                                                            detail: oldSupplier?.supplier_code || "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Supplier  Name : ",
                                                            detail: oldSupplier?.supplier_name || "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Supplier Name (สคบ.) : ",
                                                            detail: oldSupplier?.name_reserve || "-",
                                                       }}
                                                       md={12}
                                                  />
                                                  {supplier.supplier_type && supplier.supplier_type == "ประกัน" && (
                                                       <>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เบี้ยประกัน : ",
                                                                      detail: Number(oldSupplier?.insurance_premium).toLocaleString(undefined, {
                                                                           minimumFractionDigits: 2,
                                                                           maximumFractionDigits: 2,
                                                                      }),
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                      detail: oldSupplier?.supplier_tax || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </>
                                                  )}
                                                  {supplier.supplier_type && supplier.supplier_type == "Leasing" && (
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                 detail: oldSupplier?.supplier_tax || "-",
                                                            }}
                                                            md={12}
                                                       />
                                                  )}
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขโทรศัพท์ : ",
                                                            detail: oldSupplier?.supplier_tel
                                                                 ? oldSupplier?.supplier_tel === "null"
                                                                      ? "-"
                                                                      : oldSupplier?.supplier_tel
                                                                 : "-",
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                             {oldSupplier.supplier_type &&
                                                  oldSupplier.supplier_type !== "ประกัน" &&
                                                  oldSupplier.supplier_type !== "Leasing" && (
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Email : ",
                                                                      detail: oldSupplier?.supplier_mail
                                                                           ? oldSupplier?.supplier_mail === "null"
                                                                                ? "-"
                                                                                : oldSupplier?.supplier_mail
                                                                           : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ID LINE : ",
                                                                      detail: oldSupplier?.supplier_line
                                                                           ? oldSupplier?.supplier_line === "null"
                                                                                ? "-"
                                                                                : oldSupplier?.supplier_line
                                                                           : "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ธนาคาร : ",
                                                                      detail: oldSupplier?.supplier_bank || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขบัญชีธนาคาร : ",
                                                                      detail: oldSupplier?.supplier_bookbank || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       </Row>
                                                  )}
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ : ",
                                                            detail: `${oldSupplier?.supplier_address}`,
                                                       }}
                                                       md={12}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สถานะ : ",
                                                            detail: oldSupplier?.status,
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                        </div>
                                        {/* Payment Term */}
                                        <div>
                                             <LabelBar type={"title"} title={"Payment Term"} />
                                             {/* {console.log((oldSupplier?.supplier_file || "").split("/static/"))} */}
                                             <Row>
                                                  <LabelBar
                                                       onClickLabelBar={
                                                            (oldSupplier?.supplier_file || "").split("/static/")[1] !== ""
                                                                 ? () => this.openFile(oldSupplier?.supplier_file)
                                                                 : () => {}
                                                       }
                                                       textHeader_style={
                                                            (oldSupplier?.supplier_file || "").split("/static/")[1] !== "" ? "underline" : ""
                                                       }
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Book Bank :",
                                                            detail: (oldSupplier?.supplier_file || "").split("/static/")[1] !== "" ? "ดู" : "-",
                                                       }}
                                                       md={12}
                                                  />
                                             </Row>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         {supplier?.log_do === "แก้ไข" && (
                              <div className="input-field">
                                   <div className="-header">
                                        <div>การแก้ไข</div>
                                   </div>
                                   <div className="-body">
                                        <div className="content">
                                             {/* ข้อมูลการสร้าง */}
                                             <div>
                                                  <LabelBar type={"title"} title={"ข้อมูลการสร้าง"} />
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "User Create : ",
                                                                 detail: supplier?.nameuser_create,
                                                            }}
                                                            md={6}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "User Update ล่าสุด :",
                                                                 detail: supplier?.nameuser_update,
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "วันที่ Create : ",
                                                                 detail: supplier?.created_at ? dateFormatter(supplier?.created_at) : "-",
                                                            }}
                                                            md={6}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "วันที่ Update ล่าสุด : ",
                                                                 detail: supplier?.updated_at ? dateFormatter(supplier?.updated_at) : "-",
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "การอนุมัติ : ",
                                                                 detail: supplier?.log_status,
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                             </div>
                                             {/* ข้อมูล Supplier  */}
                                             <div>
                                                  <LabelBar type={"title"} title={"ข้อมูล Supplier"} />
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "Supplier Code : ",
                                                                 detail: supplier?.supplier_code || "-",
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "Supplier Name : ",
                                                                 detail: supplier?.supplier_name,
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "Supplier Name (สคบ.) : ",
                                                                 detail: supplier?.name_reserve,
                                                            }}
                                                            md={12}
                                                       />
                                                       {supplier.supplier_type && supplier.supplier_type == "ประกัน" && (
                                                            <>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "เบี้ยประกัน : ",
                                                                           detail: Number(supplier?.insurance_premium).toLocaleString(undefined, {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2,
                                                                           }),
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                           detail: supplier?.supplier_tax || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            </>
                                                       )}
                                                       {supplier.supplier_type && supplier.supplier_type == "Leasing" && (
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขประจำตัวผู้เสียภาษี : ",
                                                                      detail: supplier?.supplier_tax || "-",
                                                                 }}
                                                                 md={12}
                                                            />
                                                       )}
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "หมายเลขโทรศัพท์ : ",
                                                                 detail: supplier?.supplier_tel
                                                                      ? supplier?.supplier_tel === "null"
                                                                           ? "-"
                                                                           : supplier?.supplier_tel
                                                                      : "-",
                                                            }}
                                                            md={12}
                                                       />
                                                  </Row>
                                                  {supplier.supplier_type &&
                                                       supplier.supplier_type !== "ประกัน" &&
                                                       supplier.supplier_type !== "Leasing" && (
                                                            <Row>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "Email : ",
                                                                           detail: supplier?.supplier_mail
                                                                                ? supplier?.supplier_mail === "null"
                                                                                     ? "-"
                                                                                     : supplier?.supplier_mail
                                                                                : "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "ID LINE : ",
                                                                           detail: supplier?.supplier_line
                                                                                ? supplier?.supplier_line === "null"
                                                                                     ? "-"
                                                                                     : supplier?.supplier_line
                                                                                : "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "ธนาคาร : ",
                                                                           detail: supplier?.supplier_bank || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "เลขบัญชีธนาคาร : ",
                                                                           detail: supplier?.supplier_bookbank || "-",
                                                                      }}
                                                                      md={12}
                                                                 />
                                                            </Row>
                                                       )}
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "ที่อยู่ : ",
                                                                 detail: `${supplier?.supplier_address}`,
                                                            }}
                                                            md={12}
                                                       />
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "สถานะ : ",
                                                                 detail: supplier?.status,
                                                            }}
                                                            md={12}
                                                       />
                                                  </Row>
                                             </div>
                                             {/* Payment Term */}
                                             <div>
                                                  <LabelBar type={"title"} title={"Payment Term"} />
                                                  {/* {console.log()} */}
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "Book Bank :",
                                                                 // detail: supplier?.supplier_file.split("/static/")[1] ? "ดู" : "-",
                                                                 detail: (supplier?.supplier_file || "").split("/static/")[1] !== "" ? "ดู" : "-",
                                                            }}
                                                            md={12}
                                                            textHeader_style={
                                                                 (supplier?.supplier_file || "").split("/static/")[1] !== "" ? "underline" : ""
                                                            }
                                                            onClickLabelBar={
                                                                 (supplier?.supplier_file || "").split("/static/")[1] !== ""
                                                                      ? () => this.openFile(supplier?.supplier_file)
                                                                      : () => {}
                                                            }
                                                       />
                                                  </Row>
                                             </div>
                                             {/* ประวัติการแก้ไข */}
                                             {supplier?.log_do === "แก้ไข" && <div>{/* <LabelBar type={"title"} title={"การแก้ไข"} /> */}</div>}
                                        </div>
                                   </div>
                              </div>
                         )}

                         {/* Button */}
                         <div className="input-field">
                              <div className="button-group __submit row">
                                   <Col xs={12} md={6}>
                                        <Button type="submit" onClick={() => this.handleApprove("อนุมัติ")}>
                                             อนุมัติ
                                        </Button>
                                   </Col>
                                   <Col xs={12} md={6}>
                                        <Button variant="danger" onClick={() => this.handleApprove("ไม่อนุมัติ")}>
                                             ไม่อนุมัติ
                                        </Button>
                                   </Col>
                              </div>
                         </div>
                    </div>
               </div>
          );
     }
}
