import { Breadcrumbs, Field, LabelBar, ModalPanel, Step, Table } from "components";
import React, { Component } from "react";
import { Button, Row, Col } from "react-bootstrap";
import dayjs from "dayjs";
import { POST, ip } from "api";
import InputPanel from "components/input-panel/input-panel";
import axios from "axios";
import swal from "sweetalert";
// import printJS from "print-js";
import Spinner from "react-bootstrap/Spinner";
import { getToken } from "static/function";

function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}

export default class GenerateDeliverCar extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order_id: this.props.match.params.id,
               show_popup: false,
               deliver_status: false,
               detail: {},
               step_status: {
                    a1: false,
                    a2: false,
                    a3: false,
                    a4: false,
                    a5: false,
                    a6: false,
                    a7: false,
                    a8: false,
                    a9: false,
                    a10: false,
               },
               btn_acept: false,
               order_doc: {},
               order_doc_tax: {},
               showmodal_comfinance: false,
               showmodal_creditnote: false,
               showmodal_sign: false,
               doc_active: {},
               sign: 0,
               activeBtn: false,
               spinner: false,
               modal_select: false,
               turn_type_pdf: null,
               modal_acc_turn: false,
               sup_order: {
                    order_id: this.props.match.params.id,
               },
               supplierTurn: [],
               users: {},
               date_fixed: dayjs(new Date()),
               loading: false,
          };
     }

     componentDidMount = async () => {
          await this.setDocumentActive(12);
          await this.setState({ users: getToken() });
          this.getOrder();
     };

     setDocumentActive = (amount_doc) => {
          let { doc_active } = this.state;
          for (let i = 1; i <= amount_doc; i++) {
               doc_active[`a${i}`] = false;
          }
          this.setState({ doc_active });
          // console.log("doc : ", this.state.doc_active);
     };

     getOrder = async () => {
          let { step_status, btn_acept, order_doc, order_doc_tax, doc_active, activeBtn, users } = this.state;
          try {
               let result = await POST("/order/this", { order_id: this.state.order_id });

               if (result.accturn.length > 0) {
                    this.getSupplierTurn(this.state.order_id);
               }

               order_doc.comfinace_price = result.comfinace_price;
               order_doc.comfinace_vat = result.comfinace_vat;
               order_doc.comfinace_sum = result.comfinace_sum;
               order_doc.comfinace_date = result.comfinace_date;
               order_doc.comfinace_time = result.comfinace_time;

               order_doc_tax.reduce_tax_note = result.reduce_tax_note;
               order_doc_tax.reduce_tax_date = result.reduce_tax_date;

               //Receipt Document
               // if (result.orcp_leasing_payment_method) {
               //      let pay = JSON.parse(result.orcp_leasing_payment_method);
               //      let a = pay.filter((el) => el.check === true);
               //      let p = 0;
               //      for (let i in a) {
               //           p += Number(a[i].price);
               //      }

               //      result.receipt.push({
               //           receipt_method: "ใบเสร็จรับเงินไฟแนนซ์",
               //           receipt_price: p,
               //           check: true,
               //           code: "KO",
               //           file: "/tax_leasing_pdf",
               //      });
               // }

               // let receipt = result.receipt;

               // for (let i in receipt) {
               //      if (receipt[i].receipt_method === "ใบกำกับภาษี / ใบแจ้งหนี้ ไฟแนนซ์") {
               //           receipt.splice(i, 1);
               //      } else if (receipt[i].receipt_method === "ใบเสร็จรับเงินไฟแนนซ์") {
               //           receipt.splice(i, 1);
               //      } else if (receipt[i].receipt_method === "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์") {
               //           receipt.splice(i, 1);
               //      } else {
               //           receipt[i].active = true;
               //           receipt[i].type = "receipt";
               //      }
               // }

               // result.receipt = receipt;

               // End Receipt Document

               if (result.comfinace_date !== "0" && users?.position_id === 19 && !result.commisionfinace) {
                    doc_active.a5 = true;
               }
               if (result.comfinace_date !== "0" && (users?.position_id === 20 || users?.position_id === 10 || users?.position_id === 11)) {
                    doc_active.a5 = true;
               }

               // if (result.reduce_tax_date && users?.position_id === 19 && !result.reducetax) {
               if (result.reduce_tax_date) {
                    doc_active.a6 = true;
               }
               // if (result.reduce_tax_date && users?.position_id === 20) {
               //      doc_active.a6 = true;
               // }

               if (result.recive_date) {
                    doc_active.a11 = true;
               }

               // if (result.orcp_status === "ชำระเงินแล้ว") {
               //      doc_active.a15 = true;
               // }
               // if (result.confirm_date && users?.position_id === 19 && (!result.taxleasing || !result.tax)) {
               //      doc_active.a15 = true;
               // }
               // if (
               //      result.confirm_date &&
               //      (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               // ) {
               //      doc_active.a15 = true;
               // }
               if (result.orcp_tax_code) {
                    doc_active.a15 = true;
               }

               if (result.register_status === "จดทะเบียนแล้ว") {
                    doc_active.a12 = true;
               }

               // if (result.oder_turnpay_status === "ออกใบกำกับภาษีแล้ว") {
               if (
                    users?.position_id === 19 &&
                    (!result.paymentturnacc || !result.paymentturncar) &&
                    (result?.accturn.length > 0 || result?.carturn === 1)
               ) {
                    doc_active.a16 = true;
               }
               if (
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11) &&
                    (result?.accturn.length > 0 || result?.carturn === 1)
               ) {
                    doc_active.a16 = true;
               }

               // }

               if (result.reduce_tax_label_code && users?.position_id === 19 && !result.reducetaxlabel) {
                    doc_active.a17 = true;
               }
               if (
                    result.reduce_tax_label_code &&
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               ) {
                    doc_active.a17 = true;
               }

               if (result.reduce_register_tax_date && users?.position_id === 19 && !result.reducetaxregister) {
                    doc_active.a18 = true;
               }
               if (
                    result.reduce_register_tax_date &&
                    (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
               ) {
                    doc_active.a18 = true;
               }

               if (result.orcp_date || result.orcp_leasing_date) {
                    doc_active.a10 = true;
               }

               Object.keys(step_status).forEach((v, i) => {
                    if (result.acept_status === "ใบจองรออนุมัติ") {
                         i < 1 ? (step_status[v] = true) : (step_status[v] = false);
                         doc_active.a1 = true;
                    } else if (result.acept_status === "ใบจองอนุมัติแล้ว") {
                         i < 2 ? (step_status[v] = true) : (step_status[v] = false);
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                    } else if (result.acept_status === "รับเงินจองแล้ว") {
                         i < 3 ? (step_status[v] = true) : (step_status[v] = false);
                         doc_active.a1 = true;
                         doc_active.a2 = true;
                         if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                         if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                              doc_active.a3 = true;
                    } else if (result.acept_status === "Leasing อนุมัติแล้ว") {
                         i < 4 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                         }
                    } else if (result.acept_status === "ยืนยันข้อมูล") {
                         i < 5 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                         }
                    } else if (result.acept_status === "จับคู่รถแล้ว") {
                         i < 6 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                         }
                    } else if (result.acept_status === "ติดตั้งอุปกรณ์แล้ว") {
                         i < 7 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         result.accbonus.length === 0 && result.accbuy.length === 0 ? (step_status.a7 = false) : (step_status.a7 = true);
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                         }
                    } else if (result.acept_status === "ชำระเงินแล้ว") {
                         i < 8 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         result.accbonus.length === 0 && result.accbuy.length === 0 ? (step_status.a7 = false) : (step_status.a7 = true);
                         btn_acept = true;
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a8 = true;
                              // doc_active.a10 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                              doc_active.a8 = true;
                              // doc_active.a10 = true;
                         }
                    } else if (result.acept_status === "ส่งมอบรถแล้ว") {
                         i < 9 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         result.accbonus.length === 0 && result.accbuy.length === 0 ? (step_status.a7 = false) : (step_status.a7 = true);
                         this.setState({ deliver_status: true });
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a7 = true;
                              doc_active.a8 = true;
                              doc_active.a9 = true;
                              // doc_active.a10 = true;
                              doc_active.a13 = true;
                              activeBtn = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                              doc_active.a7 = true;
                              doc_active.a8 = true;
                              doc_active.a9 = true;
                              // doc_active.a10 = true;
                              doc_active.a13 = true;
                              activeBtn = true;
                         }
                    } else if (result.acept_status === "ปิดการขาย") {
                         i < 10 ? (step_status[v] = true) : (step_status[v] = false);
                         result.pay_choice === "เงินสด" ? (step_status.a4 = false) : (step_status.a4 = true);
                         result.accbonus.length === 0 && result.accbuy.length === 0 ? (step_status.a7 = false) : (step_status.a7 = true);
                         this.setState({ deliver_status: true });
                         if (result.pay_choice === "เงินสด") {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a7 = true;
                              doc_active.a8 = true;
                              doc_active.a9 = true;
                              // doc_active.a10 = true;
                              doc_active.a13 = true;
                         } else {
                              doc_active.a1 = true;
                              doc_active.a2 = true;
                              if (users?.position_id === 19 && !result.paymenttax) doc_active.a3 = true;
                              if (users?.position_id === 20 || users?.position_id === 25 || users?.position_id === 10 || users?.position_id === 11)
                                   doc_active.a3 = true;
                              doc_active.a4 = true;
                              doc_active.a7 = true;
                              doc_active.a8 = true;
                              doc_active.a9 = true;
                              // doc_active.a10 = true;
                              doc_active.a13 = true;
                         }
                    }
               });
               this.setState({
                    detail: { ...result },
                    step_status,
                    btn_acept,
                    order_doc,
                    order_doc_tax,
                    activeBtn,
                    date_fixed: dayjs(result.date_fixed || new Date()),
               });
               // console.log("order", this.state.detail);
          } catch (err) {
               console.log(err);
          }
     };

     getSupplierTurn = async (order_id) => {
          try {
               this.setState({ supplierTurn: [...(await POST("/order/supplier_turn", { order_id }))] });
          } catch (error) {
               console.log(error);
          }
     };

     openPopup = () => {
          let { show_popup, detail } = this.state;

          this.setState({
               show_popup: !this.state.show_popup,
          });
          // if (detail.red_label === "Yes" && !detail.recive_date) {
          //      this.setState({
          //           show_popup: false,
          //      });
          //      swal({
          //           icon: "warning",
          //           title: "กรุณาดำเนินการป้ายแดง",
          //           buttons: { Catch: { text: "ตกลง", value: "success" } },
          //           closeOnClickOutside: true,
          //      });
          // } else {
          //      this.setState({
          //           show_popup: !this.state.show_popup,
          //      });
          // }

          // if (detail.register === "Yes" && detail.register_status !== "จดทะเบียนแล้ว") {
          //      this.setState({
          //           show_popup: false,
          //      });
          //      swal({
          //           icon: "warning",
          //           title: "กรุณาดำเนินการจดทะเบียน",
          //           buttons: { Catch: { text: "ตกลง", value: "success" } },
          //           closeOnClickOutside: true,
          //      });
          // } else {
          //      this.setState({
          //           show_popup: !this.state.show_popup,
          //      });
          // }
     };

     saveSign = async (sigCanvas) => {
          let { detail, step_status, show_popup } = this.state;
          let base64 = await sigCanvas.toDataURL("image/png");
          // console.log("detail", detail);
          try {
               this.setState({ loading: true });
               await POST("/order/delivery", { ...detail, delivery_sign: base64 });
               step_status.a9 = true;
               this.setState({
                    show_popup: !this.state.show_popup,
                    deliver_status: !this.state.deliver_status,
                    btn_acept: false,
                    step_status,
               });
               window.location.reload();
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };
     clear = (sigCanvas) => {
          sigCanvas.clear();
     };

     closeModal = () => {
          this.setState({
               showmodal_comfinance: false,
               showmodal_creditnote: false,
          });
     };

     /* ----------------- handle change ------------- */
     handleChangeNumber = (e, name) => {
          let { order_doc } = this.state;
          order_doc[name] = e.floatValue;
          this.setState({ order_doc });
          // console.log(this.state.order_doc);
     };

     handleSelectDate = (date, dateString, name) => {
          let { order_doc, order_doc_tax } = this.state;
          if (name === "reduce_tax_date") {
               order_doc_tax[name] = dateString;
               this.setState({ order_doc_tax });
          } else {
               order_doc[name] = dateString;
               this.setState({ order_doc });
          }
     };
     handleChangeText = (e) => {
          let { order_doc_tax, order_doc } = this.state;
          if (e.target.name === "reduce_tax_note") {
               order_doc_tax[e.target.name] = e.target.value;
               this.setState({ order_doc_tax });
          } else {
               order_doc[e.target.name] = e.target.value;
               this.setState({ order_doc });
          }
     };

     handleChangeSelectSupplier = (e) => {
          let { sup_order } = this.state;
          sup_order[e.target.name] = e.target.value;
          this.setState({ sup_order });
     };

     downloadPdfReceipt = async (row) => {
          try {
               let token = localStorage.getItem("token");
               let { date_fixed } = this.state;
               await axios
                    .post(
                         `${ip}/order/template_pdf`,
                         {
                              order_id: this.state.order_id,
                              namepayment: row.title,
                              pricePayment: row.receipt_price,
                              code: row.receipt_code,
                              branch_id: this.state.detail.branch_id,
                              name_pay: row.name_pay,
                              // date_fixed,
                         },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     downloadPdf = async (row, type) => {
          try {
               let token = localStorage.getItem("token");
               let { date_fixed } = this.state;
               await axios
                    .post(
                         `${ip}${row.file}`,
                         {
                              order_id: this.state.order_id,
                              type: type,
                              // date_fixed
                         },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         // printJS({ printable: [fileURL, fileURL] });
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     downloadAll = async (doc) => {
          try {
               // let { spinner } = this.state;
               this.setState({ spinner: true });
               // let token = localStorage.getItem("token");
               // let files = [];
               // let receipt = doc.filter((el) => el.type === "receipt");
               // doc = doc.filter((el) => el.type !== "receipt");

               // for (let i in doc) {
               //      if (doc[i].active) {
               //           if (doc[i].title === "ใบกำกับภาษีเทิร์นสินค้า") {
               //                //TODO
               //           } else {
               //                await axios
               //                     .post(
               //                          `${ip}${doc[i].file}`,
               //                          { order_id: this.state.order_id, type: "ต้นฉบับ" },
               //                          {
               //                               responseType: "blob",
               //                               headers: {
               //                                    Accept: "application/json",
               //                                    "Content-Type": "application/json",
               //                                    token,
               //                               },
               //                          }
               //                     )
               //                     .then(async (res) => {
               //                          const pdfBlob = new Blob([res.data], {
               //                               type: "application/pdf",
               //                          });
               //                          const fileURL = URL.createObjectURL(pdfBlob);

               //                          // window.open(fileURL);
               //                          files.push(fileURL);
               //                     });
               //           }
               //      }
               // }
               // for (let i in receipt) {
               //      if (receipt[i].active) {
               //           await axios
               //                .post(
               //                     `${ip}/order/template_pdf`,
               //                     {
               //                          order_id: this.state.order_id,
               //                          namepayment: receipt[i].title,
               //                          pricePayment: receipt[i].receipt_price,
               //                          code: receipt[i].receipt_code,
               //                          branch_id: this.state.detail.branch_id,
               //                          name_pay: row.name_pay,
               //                     },
               //                     {
               //                          responseType: "blob",
               //                          headers: {
               //                               Accept: "application/json",
               //                               "Content-Type": "application/json",
               //                               token,
               //                          },
               //                     }
               //                )
               //                .then(async (res) => {
               //                     const pdfBlob = new Blob([res.data], {
               //                          type: "application/pdf",
               //                     });
               //                     const fileURL = URL.createObjectURL(pdfBlob);

               //                     // window.open(fileURL);
               //                     files.push(fileURL);
               //                });
               //      }
               // }
               let token = localStorage.getItem("token");
               let { detail, date_fixed } = this.state;
               let position_id = getToken().position_id;
               await axios
                    .post(
                         `${ip}/order/all_pdf`,
                         { order_id: this.state.order_id, pay_choice: detail.pay_choice, position_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         // const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //
                         window.open(URL.createObjectURL(pdfBlob));
                    });

               this.setState({ spinner: false });
               // this.printMultiFile(files);
          } catch (error) {
               this.setState({ spinner: false });
               console.log(`error`, error);
          }
     };

     printMultiFile = (fileArray) => {
          // console.log(`fileArray`, fileArray);

          let i = 0;
          while (i < fileArray.length) {
               window.open(fileArray[i], `file${i}`).print();
               // window.open(fileArray[i], `file${i}`).close();
               i++;
          }
     };

     loadCarTurnPDF = async () => {
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(
                         `${ip}/order/turn_tax_car_pdf`,
                         { order_id: this.state.order_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                    });
          } catch (error) {
               swal({
                    icon: "warning",
                    text: "ยังไม่ได้ออกใบกำกับภาษี",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };

     /* --------------- formatter ---------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.active) {
               if (cell) {
                    return <div style={{ color: "#000" }}>{dateFormatter(cell)}</div>;
               } else {
                    return <p>-</p>;
               }
          } else {
               return <p>-</p>;
          }
     };

     printFormatter = (cell, row) => {
          if (row.active) {
               if (row.type === "receipt") {
                    return <i className="fas fa-print icon __btn active" onClick={() => this.downloadPdfReceipt(row)}></i>;
               } else {
                    if (row.title === "ใบกำกับภาษีเทิร์นสินค้า") {
                         return <i className="fas fa-print icon __btn active" onClick={() => this.setState({ modal_select: true })}></i>;
                    } else {
                         return <i className="fas fa-print icon __btn active" onClick={() => this.downloadPdf(row, "ต้นฉบับ")}></i>;
                    }
               }
               // return <i className="fas fa-print icon __btn active" onClick={() => this.downloadPdf(row)}></i>;
          } else {
               return <i className="fas fa-print icon"></i>;
          }
     };
     docFormatter = (cell, row) => {
          if (row.active) {
               return (
                    <span className="doc active">
                         <i className="far fa-file-alt icon active"></i>
                         {row.title}
                    </span>
               );
          } else {
               return (
                    <span className="doc">
                         <i className="far fa-file-alt icon"></i>
                         {row.title}
                    </span>
               );
          }
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          switch (formatExtraData) {
               case "add_data":
                    if (row.detail === "") {
                         return <span className="doc">-</span>;
                    } else {
                         if (row.title === "ใบกรอกค่ามิซซั่นไฟแนนซ์") {
                              if (row.active) {
                                   return <a onClick={() => this.setState({ showmodal_comfinance: true })}>กรอกข้อมูล</a>;
                              } else {
                                   return <span style={{ textDecoration: "underline" }}>กรอกข้อมูล</span>;
                              }
                         } else if (row.title === "ใบลดหนี้เงินจอง") {
                              if (row.active) {
                                   return <a onClick={() => this.setState({ showmodal_creditnote: true })}>กรอกข้อมูล</a>;
                              } else {
                                   return <span style={{ textDecoration: "underline" }}>กรอกข้อมูล</span>;
                              }
                         }
                    }
          }
     };

     workflowFormatter = (cell, row) => {
          if (row.file) {
               if (row.active) {
                    if (row.show) {
                         if (row.title === "ใบรับจองรถยนต์") {
                              return <a onClick={() => this.setState({ showmodal_sign: true, sign: 0 })}>{cell}</a>;
                         } else if (row.title === "ใบกำกับภาษีเงินจอง") {
                              return <a onClick={() => this.setState({ showmodal_sign: true, sign: 1 })}>{cell}</a>;
                         } else if (row.title === "ใบส่งมอบรถ") {
                              return <a onClick={() => this.setState({ showmodal_sign: true, sign: 2 })}>{cell}</a>;
                         } else if (row.title === "สัญญาจองรถยนต์ (สคบ.)") {
                              return <a onClick={() => this.setState({ showmodal_sign: true, sign: 3 })}>{cell}</a>;
                         }
                    } else {
                         return (
                              <a
                              // onClick={this.openPopup}
                              >
                                   {cell}
                              </a>
                         );
                    }
               } else {
                    return <span style={{ textDecoration: "underline" }}>{cell}</span>;
               }
          } else {
               return null;
          }
     };

     /* ----------------- Body ----------------------- */
     modalViewInputComfinance = () => {
          let { detail, order_doc } = this.state;

          return (
               <div style={{ padding: "0 15%" }}>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">Series</span>
                              </Row>
                              <Row>
                                   <span>{detail?.series_name}</span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="number"
                                   name={"comfinace_price"}
                                   value={order_doc?.comfinace_price}
                                   title={"ค่าคอมไฟแนนซ์"}
                                   placeholder={"0.00"}
                                   decimalScale={2}
                                   handleChangeNumber={(e) => this.handleChangeNumber(e, "comfinace_price")}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">Model</span>
                              </Row>
                              <Row>
                                   <span>{detail?.model_name}</span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="number"
                                   name={"comfinace_vat"}
                                   value={order_doc?.comfinace_vat}
                                   title={"VAT"}
                                   placeholder={"0.00"}
                                   decimalScale={2}
                                   handleChangeNumber={(e) => this.handleChangeNumber(e, "comfinace_vat")}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">สี</span>
                              </Row>
                              <Row>
                                   <span>{detail?.color_name}</span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="number"
                                   name={"comfinace_sum"}
                                   value={order_doc?.comfinace_sum}
                                   title={"ทั้งหมด"}
                                   placeholder={"0.00"}
                                   decimalScale={2}
                                   handleChangeNumber={(e) => this.handleChangeNumber(e, "comfinace_sum")}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">หมายเลขเครื่องยนต์</span>
                              </Row>
                              <Row>
                                   <span>{detail?.car_engine}</span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="date"
                                   name={"comfinace_date"}
                                   title={"ประจำวันที่"}
                                   placeholder={order_doc.comfinace_date ? order_doc.comfinace_date : "วว/ดด/ปปปป"}
                                   handleSelectDate={this.handleSelectDate}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">ราคาทั้งหมด</span>
                              </Row>
                              <Row>
                                   <span>{detail?.real_price}</span>
                              </Row>
                         </Col>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="text"
                                   name={"comfinace_time"}
                                   value={order_doc?.comfinace_time}
                                   title={"งวดที่"}
                                   placeholder={"กรอกงวดที่"}
                                   handleChangeText={(e) => this.handleChangeText(e)}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Row>
                                   <span className="gray">ผู้ซื้อ</span>
                              </Row>
                              <Row>
                                   <span>{detail?.userinfo_name}</span>
                              </Row>
                         </Col>
                         <Col></Col>
                    </Row>
               </div>
          );
     };

     modalViewSelectSupplier = () => {
          let { supplier_acc, sup_order, supplierTurn } = this.state;
          let supplier_option = [];
          for (let i in supplierTurn) {
               supplier_option.push({ value: supplierTurn[i].supplier_id, name: supplierTurn[i].supplier_name });
          }

          return (
               <div style={{ padding: "0 15%" }}>
                    <Field
                         value={sup_order?.supplier_id}
                         type="select"
                         name={"supplier_id"}
                         title={"Supplier"}
                         option={supplier_option}
                         handleChangeText={(e) => this.handleChangeSelectSupplier(e)}
                    />
               </div>
          );
     };

     modalViewCreditNote = () => {
          let { order_doc_tax } = this.state;
          // console.log("order", carorder);

          return (
               <div style={{ padding: "0 15%" }}>
                    <Row>
                         <Col>
                              <InputPanel
                                   type={"textarea"}
                                   name={"reduce_tax_note"}
                                   rows={3}
                                   value={order_doc_tax?.reduce_tax_note}
                                   title={"สาเหตุการลดหนี้"}
                                   placeholder={"กรอกสาเหตุการลดหนี้"}
                                   handleChange={(e) => this.handleChangeText(e)}
                              />
                         </Col>
                    </Row>
                    <Row>
                         <Col>
                              <Field
                                   // value={sup_order?.supplier_id}
                                   type="date"
                                   name={"reduce_tax_date"}
                                   title={"ประจำวันที่"}
                                   placeholder={order_doc_tax.reduce_tax_date ? order_doc_tax.reduce_tax_date : "เลือกวันที่"}
                                   handleSelectDate={this.handleSelectDate}
                              />
                         </Col>
                         <Col></Col>
                    </Row>
               </div>
          );
     };

     modalSelectSupplier = () => {
          let { detail } = this.state;
          return (
               <div style={{ padding: "0 10%" }}>
                    <LabelBar
                         title={"ใบกำกับภาษี"}
                         type={"radio"}
                         label={detail.carturn === 0 ? ["อุปกรณ์ตกแต่ง"] : ["รถยนต์", "อุปกรณ์ตกแต่ง"]}
                         value={this.state.turn_type_pdf}
                         handleChangeText={(e) => {
                              this.setState({ turn_type_pdf: e.target.value });
                         }}
                    />
               </div>
          );
     };
     openSupPDF = async () => {
          let { sup_order, detail } = this.state;
          try {
               let token = localStorage.getItem("token");
               await axios
                    .post(`${ip}/order/turn_tax_pdf`, sup_order, {
                         responseType: "blob",
                         headers: {
                              Accept: "application/json",
                              "Content-Type": "application/json",
                              token,
                         },
                    })
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         // -- SET STATE WITH FILE URL AND SHOW MODAL -- //

                         window.open(fileURL);
                         this.setState({ modal_select: false });
                         // this.setState({
                         //     fileURL: fileURL,
                         //     // spinner: false,
                         //     // show_modal_pdf: true
                         // });
                    });
          } catch (error) {
               swal({
                    icon: "warning",
                    text: "ยังไม่ได้ออกใบกำกับภาษี",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
               console.log(`error`, error);
          }
     };
     openLoadPdfTurn = () => {
          let { turn_type_pdf, detail } = this.state;
          if (turn_type_pdf === "รถยนต์") {
               this.loadCarTurnPDF();
          } else if (turn_type_pdf === "อุปกรณ์ตกแต่ง") {
               if (detail.accturn.length > 0) {
                    this.setState({ modal_acc_turn: true, modal_select: false });
               } else {
                    swal({
                         icon: "warning",
                         text: "ยังไม่ได้ออกใบกำกับภาษี",
                         buttons: {
                              submit: "ตกลง",
                         },
                    });
               }
          } else {
               swal({
                    icon: "warning",
                    text: "กรุณาเลือก",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };
     updateDatefixed = async () => {
          try {
               let { order_id, date_fixed, detail } = this.state;
               let date = dayjs(date_fixed).format("YYYY-MM-DD HH:mm:ss");
               // console.log(
               //      `order_id,date_fixed`,
               //      order_id,
               //      date,
               //      dayjs(date_fixed).format("YYYY-MM-DD") >= dayjs(detail.orp_updated_at).format("YYYY-MM-DD")
               // );
               if (dayjs(date_fixed).format("YYYY-MM-DD") >= dayjs(detail.orp_updated_at).format("YYYY-MM-DD")) {
                    await POST("/aos/update_datefixed", { id: order_id, date_fixed: date });
                    swal({
                         icon: "success",
                         text: "สำเร็จ",
                         buttons: {
                              submit: "ตกลง",
                         },
                    }).then(() => this.getOrder());
               } else {
                    swal({
                         icon: "warning",
                         text: "วันที่ต้องตรงกับใบกำกับภาษีเงินจองหรือมากกว่าเท่านั้น",
                         buttons: {
                              submit: "ตกลง",
                         },
                    });
               }
          } catch (error) {
               swal({
                    icon: "error",
                    text: "ไม่สำเร็จ! กรุณาลองใหม่",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };
     render() {
          let { show_popup, detail, step_status, btn_acept, doc_active, sign, order_id, activeBtn, loading } = this.state;
          if (detail && detail.pay_choice === "ผ่อนชำระ" && detail.receipt.some((el) => el.receipt_method === "ใบเสร็จรับเงินค่ารถ")) {
               detail.receipt.splice(
                    detail.receipt.findIndex((el) => el.receipt_method === "ใบเสร็จรับเงินค่ารถ"),
                    1
               );
          }
          const doc = {
               column: [
                    {
                         dataField: "title",
                         text: "รายการเอกสาร",
                         formatter: this.docFormatter,
                         headerClasses: "header-custom __left __btn-l",
                    },
                    {
                         dataField: "create_date",
                         text: "วันที่ออกเอกสาร",
                         formatter: this.dateFormatter,
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "add",
                         text: "กรอกข้อมูล",
                         formatter: this.detailFormatter,
                         formatExtraData: "add_data",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "sign",
                         text: "Sign on Mobile",
                         formatter: this.workflowFormatter,
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "file",
                         text: "พิมพ์",
                         formatter: this.printFormatter,
                         headerClasses: "header-custom __right __icon",
                    },
               ],
               data: [
                    {
                         file: "/order/order_leadsing_pdf",
                         title: "ใบรับจองรถยนต์",
                         create_date: detail?.created_at,
                         detail: "",
                         sign: "Sign on Mobile",
                         active: doc_active.a1,
                         show: true,
                    },
                    // {
                    //      file: "/order/reservation_pdf",
                    //      title: "สัญญาจองรถยนต์ (สคบ.)",
                    //      create_date: detail?.created_at,
                    //      detail: "",
                    //      sign: "Sign on Mobile",
                    //      active: doc_active.a1,
                    //      show: "xxx",
                    // },
                    {
                         file: "/order/order_margin_pdf",
                         title: "ใบอนุมัติการลดเกิน Margin / ขอเครดิต",
                         create_date: detail?.margin_acept_date,
                         detail: "",
                         sign: "",
                         active: doc_active.a2,
                    },
                    {
                         file: "/order/order_payment_tax_pdf",
                         title: "ใบกำกับภาษีเงินจอง",
                         create_date: detail?.orp_updated_at,
                         detail: "",
                         sign: "Sign on Mobile",
                         active: doc_active.a3,
                         show: true,
                    },
                    {
                         file: "/order/get_payment_leasing_pdf",
                         title: "ใบยืนยันลีสซิ่ง",
                         create_date: detail?.leasing_acecpt_date,
                         detail: "",
                         sign: "",
                         active: doc_active.a4,
                    },
                    {
                         file: detail?.pay_choice === "เงินสด" ? "/order/tax_pdf" : "/order/tax_leasing_pdf",
                         // title: "ใบแจ้งหนี้/ใบกำกับภาษีค่ารถ",
                         title: "ใบแจ้งหนี้",
                         // create_date: detail?.confirm_date,
                         create_date: detail?.delivery_date,
                         detail: "",
                         sign: "",
                         active: doc_active.a15,
                    },
                    {
                         file: "/order/commission_finance_pdf",
                         title: "ใบกรอกค่ามิซซั่นไฟแนนซ์",
                         create_date: detail?.comfinace_saveDate,
                         // detail: "กรอกข้อมูล",
                         detail: "",
                         sign: "",
                         active: doc_active.a5,
                    },
                    // {
                    //      file: '/order/reduce_tax_pdf',
                    //      title: 'ใบลดหนี้เงินจอง',
                    //      create_date: detail?.reduce_tax_saveDate,
                    //      // detail: "กรอกข้อมูล",
                    //      detail: '',
                    //      sign: '',
                    //      active: doc_active.a6,
                    // },
                    {
                         file: "/order/reduce_tax_register_pdf",
                         title: "ใบคืนเงินค่ามัดจำชุดจดทะเบียน",
                         create_date: detail?.reduce_register_tax_date,
                         detail: "",
                         sign: "",
                         active: doc_active.a18,
                    },
                    {
                         file: "/order/delivery_pdf",
                         title: "ใบส่งมอบรถ",
                         create_date: detail?.delivery_date,
                         detail: "",
                         sign: "Sign on Mobile",
                         active: doc_active.a7,
                         show: true,
                    },
                    // {
                    //      file: "/order/car_pay_pdf",
                    //      title: "ใบกำกับภาษีค่ารถ",
                    //      create_date: detail?.orcp_updated_at,
                    //      detail: "",
                    //      sign: "",
                    //      active: doc_active.a8,
                    // },
                    {
                         file: "/order/car_out_pdf",
                         title: "ใบอนุญาตนำรถออก",
                         create_date: detail?.delivery_date,
                         detail: "",
                         sign: "",
                         active: doc_active.a9,
                    },
                    {
                         file: detail?.pay_choice === "เงินสด" ? "/order/car_pay_pdf" : "/order/car_pay_leasing_pdf",
                         title: "ใบเสร็จรับเงิน",
                         create_date: detail?.pay_choice === "เงินสด" ? detail?.orcp_date : detail?.orcp_leasing_date,
                         detail: "",
                         sign: "",
                         active: doc_active.a10,
                    },
                    // {
                    //      file: "/order/tempory_label_pdf",
                    //      title: "ใบรับเงินชั่วคราวป้ายแดง",
                    //      create_date: detail?.recive_date,
                    //      detail: "",
                    //      sign: "",
                    //      // active: doc_active.a11,
                    //      active: false,
                    // },
                    {
                         file: "/order/reduce_tax_label_pdf",
                         title: "ใบเสร็จคืนเงินป้ายแดง",
                         // create_date: detail?.label_return_date,
                         create_date: null,
                         detail: "",
                         sign: "",
                         // active: doc_active.a17,
                         active: false,
                    },
                    // {
                    //      file: "/order/tempory_register_pdf",
                    //      title: "ใบรับเงินชั่วคราวค่าจดทะเบียนรถยนต์",
                    //      create_date: detail?.orcp_updated_at,
                    //      detail: "",
                    //      sign: "",
                    //      active: doc_active.a12,
                    // },
                    {
                         file: "/order/summary_delivery_car",
                         title: "สรุปรายการรับเงินวันส่งมอบรถ",
                         create_date: detail?.delivery_date,
                         detail: "",
                         sign: "",
                         active: doc_active.a13,
                    },
                    //  เก็บไว้ก่อน
                    // {
                    //      file: "/car/borrow_label_pdf",
                    //      title: "ใบยืมป้ายแดง",
                    //      create_date: carorder?.checklabel_date,
                    //      detail: "-",
                    //      sign: "-",
                    //      active: doc_active.a14,
                    // },
                    // {
                    //      file: "/order/tax_pdf",
                    //      title: "ใบแจ้งหนี้",
                    //      create_date: detail?.confirm_date,
                    //      detail: "-",
                    //      sign: "",
                    //      active: doc_active.a15,
                    // },
                    {
                         file: "/order/turn_tax_pdf",
                         title: "ใบกำกับภาษีเทิร์นสินค้า",
                         create_date: detail?.order_turn_payment_updated_at,
                         detail: "",
                         sign: "",
                         active: doc_active.a16,
                    },
                    ...(detail.receipt ? detail.receipt : []).map((el) => ({
                         file: el?.file,
                         title: el?.receipt_method,
                         // create_date: el?.date,
                         create_date: el?.ordate,
                         detail: "",
                         sign: "",
                         active: true,
                         receipt_code: el?.receipt_code,
                         receipt_id: el?.receipt_id,
                         receipt_price: el?.receipt_price,
                         type: "receipt",
                         name_pay: el.name_pay || "",
                    })),
               ],
               keyField: "title",
          };
          if (detail?.pay_choice !== "เงินสด" || detail?.order_fleet) {
               doc.data.splice(
                    doc.data.findIndex((el) => el.title === "ใบเสร็จรับเงิน"),
                    1
               );
          }
          // if (detail.register !== "Yes") {
          //      doc.data.splice(8, 1);
          // }
          let step = [
               {
                    title: "ใบจองรออนุมัติ",
                    active: step_status.a1,
               },
               {
                    title: "ใบจองอนุมัติแล้ว",
                    active: step_status.a2,
               },
               {
                    title: "รับเงินจองแล้ว",
                    active: step_status.a3,
               },
               {
                    title: "Leasing อนุมัติแล้ว",
                    active: step_status.a4,
               },
               {
                    title: "ยืนยันข้อมูล",
                    active: step_status.a5,
               },
               {
                    title: "จับคู่รถแล้ว",
                    active: step_status.a6,
               },
               {
                    title: "ติดตั้งอุปกรณ์แล้ว",
                    active: step_status.a7,
               },
               {
                    title: "ชำระเงินแล้ว",
                    active: step_status.a8,
               },
               // {
               //      title: "รอส่งมอบ",
               //      active: step_status.a9,
               // },
               {
                    title: "ส่งมอบรถแล้ว",
                    active: step_status.a9,
               },
               {
                    title: "ปิดการขาย",
                    active: step_status.a10,
                    activeBtn: activeBtn,
                    btn: () => window.location.assign(`/admin/order/car/detail${order_id}`),
               },
          ];
          if (detail?.pay_choice === "เงินสด") step.splice(3, 1);
          if ((detail.accbuy && detail.accbuy.length === 0) || (detail.accbonus && detail.accbonus.length === 0)) {
               step.splice(
                    step.findIndex((el) => el.title === "ติดตั้งอุปกรณ์แล้ว"),
                    1
               );
          }
          return (
               <div className="panel">
                    <Breadcrumbs
                         title={[
                              {
                                   title: `ส่งมอบรถ`,
                                   onClick: () => window.location.assign("/admin/deliver/car"),
                              },
                              {
                                   title: `ใบจองรถยนต์ (${detail?.order_code_id || " "})`,
                                   onClick: () => window.location.assign(`/admin/order/car/detail${order_id}`),
                              },
                         ]}
                         active={2}
                         button={""}
                    />

                    <div className="content __input-panel">
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        <LabelBar type={"title"} title={"สถานะของใบจอง"} />
                                        <Step step={step} />
                                   </div>
                              </div>
                         </div>
                         <div className="input-field">
                              <div className="-body">
                                   <div className="content">
                                        {/* detail showroom */}
                                        <LabelBar type={"title"} title={"ข้อมูลโชว์รูม"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "โชว์รูม : ",
                                                            detail: detail?.branch_name || "-",
                                                       }}
                                                       md={7}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "เลขที่ใบจอง : ",
                                                            detail: detail?.order_code_id || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "พนักงานขาย : ",
                                                            detail: detail?.saler_name_string || "-",
                                                       }}
                                                       md={7}
                                                  />
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่ออกใบจอง : ",
                                                            detail: detail.created_at ? dateFormatter(detail?.created_at) : "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันที่คาดว่าจะได้รับรถ : ",
                                                            detail: detail.getcar_date ? dateFormatter(detail.getcar_date) : "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                        </div>

                                        {/* detail */}
                                        <LabelBar type={"title"} title={"รายละเอียดผู้สั่งจอง"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "A-Card No. : ",
                                                            detail: detail?.userinfo_a_card || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อ-นามสกุล ผู้สั่งจอง : ",
                                                            detail: detail?.userinfo_name || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขประจำตัว : ",
                                                            detail: detail?.userinfo_idcard || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขโทรศัพท์ : ",
                                                            detail: detail?.userinfo_tel ? this.telFormat(String(detail?.userinfo_tel)) : "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ID LINE : ",
                                                            detail: detail?.userinfo_line || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "วันเกิด : ",
                                                            detail: detail?.userinfo_birth_date ? dateFormatter(detail?.userinfo_birth_date) : "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ : ",
                                                            detail: `${detail?.userinfo_address || "-"}  
                                                            ตำบล/แขวง ${detail?.userinfo_sub_district || "-"} 
                                                            อำเภอ/เขต ${detail?.userinfo_district || "-"} 
                                                            จังหวัด ${detail?.userinfo_province || "-"} 
                                                            ${detail?.userinfo_zipcode || "-"}`,
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ที่ใช้ติดต่อ : ",
                                                            detail: `${detail?.userinfo_address || "-"}  
                                                            ตำบล/แขวง ${detail?.userinfo_sub_district || "-"} 
                                                            อำเภอ/เขต ${detail?.userinfo_district || "-"} 
                                                            จังหวัด ${detail?.userinfo_province || "-"} 
                                                            ${detail?.userinfo_zipcode || "-"}`,
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                        </div>

                                        {/* detail */}
                                        <LabelBar type={"title"} title={"รายละเอียดผู้ถือครอง"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ชื่อ-นามสกุล ผู้ถือครอง : ",
                                                            detail: detail?.owner_name || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "หมายเลขประจำตัว : ",
                                                            detail: detail?.owner_idcard || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "ที่อยู่ : ",
                                                            detail: `${detail?.owner_address || "-"}  
                                                            ตำบล/แขวง ${detail?.owner_sub_district || "-"} 
                                                            อำเภอ/เขต ${detail?.owner_district || "-"} 
                                                            จังหวัด ${detail?.owner_province || "-"} 
                                                            ${detail?.owner_zipcode || "-"}`,
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                        </div>

                                        {/* detail car */}
                                        <LabelBar type={"title"} title={"เลือกรุ่นรถยนต์"} />
                                        <div className="-padding">
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Serie : ",
                                                            detail: detail?.series_name || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Model : ",
                                                            detail: detail?.model_name || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สีที่ 1 : ",
                                                            detail: detail?.color_name || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "สีที่ 2 : ",
                                                            detail: `-`,
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                             <Row>
                                                  <LabelBar
                                                       type={"textheader"}
                                                       title={{
                                                            name: "Model Code : ",
                                                            detail: detail?.model_code || "-",
                                                       }}
                                                       md={7}
                                                  />
                                             </Row>
                                        </div>
                                        {/* {console.log(`getToken().position_id`, this.state.date_fixed)} */}
                                        {/* {(getToken().position_id === 19 || getToken().position_id === 20 || getToken().position_id === 25) && ( */}

                                        {/* check document */}
                                        <LabelBar type={"title"} title={"ตรวจสอบเอกสาร"} />
                                        <div className="-padding">
                                             {detail.acept_status === "ส่งมอบรถแล้ว" && (
                                                  <Row>
                                                       <strong>เปลี่ยนแปลงวันที่ตัดขายและส่งมอบ</strong>
                                                       <Field
                                                            id={"field-get-car-date"}
                                                            type={"date"}
                                                            // title={"วันที่สร้างใบจอง"}
                                                            name={"date_fixed"}
                                                            value={this.state.date_fixed}
                                                            // disabled={true}
                                                            placeholder={"วว/ดด/ปปปป"}
                                                            formatDate={"DD/MM/YYYY"}
                                                            md={3}
                                                            handleSelectDate={(date) => {
                                                                 this.setState({ date_fixed: dayjs(date) });
                                                            }}
                                                       />
                                                       <Button style={{ width: 55, height: 35, fontSize: "x-small" }} onClick={this.updateDatefixed}>
                                                            SAVE
                                                       </Button>
                                                  </Row>
                                             )}

                                             <div className="-body">
                                                  <ModalPanel
                                                       show={this.state.showmodal_sign}
                                                       title={"Sign"}
                                                       type={"image"}
                                                       image={
                                                            (sign === 0 && detail?.order_sign) ||
                                                            (sign === 1 && detail?.orp_sign) ||
                                                            (sign === 2 && detail?.delivery_sign) ||
                                                            (sign === 3 && detail?.reservation_sign)
                                                       }
                                                       onHide={() => this.setState({ showmodal_sign: false })}
                                                  />
                                                  <div className="content" id="table-content">
                                                       <Table type="document" column={doc.column} data={doc.data} keyField={doc.keyField} />
                                                  </div>
                                                  <div className="button-group __submit row">
                                                       {this.state.spinner ? (
                                                            <Col xs={6}>
                                                                 <Button variant="outline-primary" disabled={false}>
                                                                      <Spinner
                                                                           as="span"
                                                                           animation="grow"
                                                                           size="sm"
                                                                           role="status"
                                                                           aria-hidden="true"
                                                                      />
                                                                      {` กำลังดาวน์โหลด...`}
                                                                 </Button>
                                                            </Col>
                                                       ) : (
                                                            <Col xs={6}>
                                                                 {!btn_acept && (
                                                                      <Button onClick={() => this.downloadAll(doc.data)}>พิมพ์ทั้งหมด</Button>
                                                                 )}
                                                            </Col>
                                                       )}
                                                  </div>
                                             </div>
                                        </div>

                                        {/* // )} */}
                                        <div className="button-group __submit row">
                                             {btn_acept && (
                                                  <Col xs={6}>
                                                       {loading ? (
                                                            <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                                 <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                                 Loading...
                                                            </Button>
                                                       ) : (
                                                            <Button onClick={this.openPopup}>ยืนยันการรับรถ</Button>
                                                       )}
                                                  </Col>
                                             )}
                                        </div>
                                   </div>
                              </div>
                              <ModalPanel
                                   show={show_popup}
                                   type={"sign"}
                                   save={this.saveSign}
                                   clear={this.clear}
                                   title={"ยืนยันการรับรถ"}
                                   sub_title={"ลายเซ็นลูกค้า"}
                                   // onClick={this.openPopup}
                                   onHide={() => this.setState({ show_popup: false })}
                              />

                              <ModalPanel
                                   show={this.state.showmodal_comfinance}
                                   title={"กรอกค่าคอมไฟแนนซ์"}
                                   type={"input"}
                                   body={this.modalViewInputComfinance()}
                                   onHide={this.closeModal}
                                   button={[{ onClick: this.closeModal, name: "Submit", variant: "primary" }]}
                              />
                              <ModalPanel
                                   show={this.state.showmodal_creditnote}
                                   // title={"กรอกค่าคอมไฟแนนซ์"}
                                   type={"input"}
                                   body={this.modalViewCreditNote()}
                                   onHide={this.closeModal}
                                   button={[
                                        { onClick: this.closeModal, name: "ตกลง", variant: "primary" },
                                        { onClick: () => {}, name: "Reset", variant: "danger" },
                                   ]}
                              />
                              <ModalPanel
                                   show={this.state.modal_select}
                                   title={"เลือกประเภทใบกำกับภาษี"}
                                   type={"input"}
                                   body={this.modalSelectSupplier()}
                                   onHide={() => this.setState({ modal_select: false })}
                                   button={[{ onClick: this.openLoadPdfTurn, name: "ตกลง", variant: "primary" }]}
                              />

                              <ModalPanel
                                   show={this.state.modal_acc_turn}
                                   title={"เลือก Supplier สำหรับอุปกรณ์ตกแต่ง"}
                                   type={"input"}
                                   body={this.modalViewSelectSupplier()}
                                   onHide={() => this.setState({ modal_acc_turn: false })}
                                   button={[{ onClick: this.openSupPDF, name: "ตกลง", variant: "primary" }]}
                              />

                              {this.state.deliver_status ? (
                                   // Breadcrumbs Label
                                   <div>
                                        <Breadcrumbs
                                             under={true}
                                             type={"success"}
                                             title={"ส่งมอบรถแล้ว"}
                                             // button={
                                             //     { onClick: () => { }, name: 'พิมพ์ใบยืนยันส่งมอบรถ', variant: 'outline-primary' }
                                             // }
                                        />
                                        <div style={{ height: "80px" }}></div>
                                   </div>
                              ) : null}
                         </div>
                    </div>
               </div>
          );
     }
}
