import React, { Component } from "react";
import { Breadcrumbs, Field, LabelBar, Table, ModalPanel, Loader } from "components/index";
import { Form, Button, Col, Row, Image, Spinner } from "react-bootstrap";
import swal from "sweetalert";
import dayjs from "dayjs";
import { POST, GET, ip } from "api/index";
import { useEffect } from "react";
import { getToken } from "../../../../static/function";

function ScrollToTopOnMount() {
     useEffect(() => {
          window.scrollTo(0, 0);
     }, []);
     return null;
}

export default class ACardManage extends Component {
     constructor(props) {
          super(props);
          this.state = {
               previous_path: this.props.match.params.manage,
               title: [],
               a_card: {
                    abroad: "ใน",
               },
               order: {},
               validate: false,
               branch: [],
               province: [],
               district: [],
               sub_district: [],
               zip_code: [],
               show_modal_add_success: false,
               show_modal_edit_success: false,
               // AcardInfo: [],
               seller: [],
               user: {},
               series: [],
               color: [],
               model: [],
               placeholder: "แนบหน้าสมุดบัญชี",
               dateSelect: { acard_create: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss") },
               showImage: false,
               loading: false,
               acard_result: [],
               name_normal: [],
               name_business: [],
               loading_data: false,
          };
     }

     componentDidMount = async () => {
          await this.setState({ user: getToken() });
          await this.getSeller();
          this.getData();
          let e = [this.state.seller.find((el) => el.user_id === this.state.user?.user_id)?.name];
          this.handleChangeAutocomplete(e);
          this.setPath();
          this.getAddress();
          this.getSeriesCars();
          this.getName();
     };

     /* ------------------------- Set/Get -------------------------- */
     setPath = () => {
          let { previous_path, title, a_card, user } = this.state;

          switch (previous_path) {
               case "add":
                    title = [
                         {
                              title: "A-Card",
                              onClick: () => window.location.assign("/admin/a_card"),
                         },
                         {
                              title: "เพิ่ม A-Card",
                         },
                    ];

                    a_card.acard_saler = user.user_id;
                    a_card.acard_create = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
                    if (user.branch_id) {
                         if (Number(user.branch_id) === 99) {
                              a_card.branch_id = "1";
                         } else {
                              a_card.branch_id = user.branch_id;
                         }
                    }
                    break;
               case "edit":
                    title = [
                         {
                              title: "A-Card",
                              onClick: () => window.location.assign("/admin/a_card"),
                         },
                         {
                              title: "รายการ A-Card",
                              onClick: () => window.location.assign("/admin/a_card/list"),
                         },
                         {
                              title: "ข้อมูล A-Card",
                         },
                    ];
                    let acard_id_props = this.props.location.state.acard_id;
                    this.getAcardInfo(acard_id_props);
                    break;
               default:
                    break;
          }
          this.setState({ title: title, a_card });
          // console.log(this.state.a_card);
     };

     /*-----------------------------API------------------------------------*/
     getSeller = async () => {
          try {
               let seller = await GET("/ps/sellers");
               this.setState({ seller });
          } catch (err) {
               console.log(err);
          }
     };

     getData = async () => {
          try {
               let getBranch = await GET("/ps/get_branchs");
               await this.setState({
                    branch: getBranch,
               });
          } catch (error) {
               // console.log(error);
          }
     };
     getAcardInfo = async (acard_id) => {
          this.setState({ loading_data: true });
          try {
               let { dateSelect } = this.state;
               let a_card, placeholder;
               let AcardInfo = await POST("/aos/get_acard", { acard_id: acard_id });
               AcardInfo.map((ele) => (a_card = ele));
               dateSelect.acard_create = dayjs(a_card.acard_create);
               dateSelect.acard_birthdate = dayjs(a_card.acard_birthdate);
               dateSelect.acard_test_date = dayjs(a_card.acard_test_date);
               await this.getAddress(a_card.acard_province);
               await this.getAddress(a_card.acard_province, a_card.acard_district);
               await this.getAddress(a_card.acard_province, a_card.acard_district, a_card.acard_sub_district);
               if (a_card.book_bank && a_card.book_bank.length >= 20) {
                    placeholder = a_card.book_bank.substring(0, 20) + "...";
               } else if (!a_card.book_bank) {
                    placeholder = "แนบหน้าสมุดบัญชี";
               } else {
                    placeholder = a_card.book_bank;
               }
               if (a_card.series_id) {
                    await this.getModel(a_card.series_id);
                    this.getColors(a_card.model_id);
                    a_card.model_code = this.state.model.find((el) => el.model_id === a_card.model_id)?.model_code;
               }
               this.setState({
                    a_card,
                    placeholder,
                    loading_data: false,
               });
          } catch (error) {
               this.setState({ loading_data: false });
               console.log(error);
          }
     };
     getName = async () => {
          try {
               let result = await GET("/aos/get_acard");
               let name_normal = [];
               let name_business = [];
               result.map((el) => {
                    if (el.acard_customer_type === "บุคคลทั่วไป") {
                         name_normal.push({ acard_firstname: el.acard_firstname, acard_id: el.acard_id });
                    } else if (el.acard_customer_type === "นิติบุคคล") {
                         name_business.push({ acard_firstname: el.acard_firstname, acard_id: el.acard_id });
                    } else {
                         name_normal.push({ acard_firstname: el.acard_firstname, acard_id: el.acard_id });
                    }
               });
               this.setState({ acard_result: result, name_normal, name_business });
          } catch (error) {
               console.log(`error`, error);
          }
     };
     getAddress = async (province_pass, district_pass, sub_district_pass) => {
          try {
               let getAddress = await POST("/aos/search_address", {
                    province: province_pass,
                    district: district_pass,
                    sub_district: sub_district_pass,
               });
               if (getAddress.type === "No") {
                    await this.setState({
                         province: getAddress.array,
                    });
               } else if (getAddress.type === "province") {
                    await this.setState({
                         district: getAddress.array,
                    });
               } else if (getAddress.type === "district") {
                    await this.setState({
                         sub_district: getAddress.array,
                    });
               } else if (getAddress.type === "sub_district") {
                    let adress = getAddress.array.map((ele) => ele.postcode);
                    let zip_code = adress;
                    await this.setState({
                         zip_code,
                    });
               }
          } catch (error) {
               // console.log(error);
          }
     };
     getSeriesCars = async () => {
          try {
               let getseries = await GET("/car/series");
               this.setState({ series: [...getseries] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getModel = async (series_id) => {
          try {
               let getmodel = await POST("/car/model", { series_id });
               this.setState({ model: [...getmodel] });
          } catch (error) {
               console.log("error ", error);
          }
     };

     getColors = async (model_id) => {
          try {
               let getcolor = await POST("/car/color", { model_id });
               this.setState({ color: [...getcolor] });
          } catch (error) {
               console.log("error ", error);
          }
     };
     postData = async () => {
          this.setState({ loading: true });
          let { a_card, fileImage } = this.state;
          try {
               let res = await POST("/aos/create_acard", {
                    userinfo_idcard: a_card.userinfo_idcard,
                    userinfo_address: a_card.userinfo_address,
                    acard_saler: a_card.acard_saler,
                    branch_id: Number(a_card.branch_id),
                    acard_create: dayjs(a_card.acard_create).format("YYYY-MM-DD HH:mm:ss"),
                    // acard_no: a_card.acard_no,
                    acard_gms: a_card.acard_gms,
                    acard_from: a_card.acard_from,
                    acard_customer_type: a_card.acard_customer_type,
                    acard_prefix: a_card.acard_prefix,
                    acard_firstname: a_card.acard_firstname,
                    acard_lastname: a_card.acard_lastname,
                    acard_gender: a_card.acard_gender,
                    acard_birthdate: dayjs(a_card.acard_birthdate).format("YYYY-MM-DD HH:mm:ss"),
                    acard_tel: a_card.acard_tel,
                    acard_line: a_card.acard_line,
                    acard_province: a_card.acard_province,
                    acard_district: a_card.acard_district,
                    acard_sub_district: a_card.abroad === "ใน" ? a_card.acard_sub_district : "-",
                    acard_zipcode: a_card.acard_zipcode,
                    status: a_card.status,
                    acard_test: a_card.acard_test,
                    acard_test_date: dayjs(a_card.acard_test_date).format("YYYY-MM-DD HH:mm:ss"),
                    acard_pdpa: a_card.acard_pdpa,
                    bank_name: a_card.bank_name,
                    bank_number: a_card.bank_number,
                    account_bank: a_card.account_bank,
                    book_bank: a_card.book_bank,
                    series_id: a_card.series_id,
                    model_id: a_card.model_id,
                    color_id: a_card.color_id,
                    abroad: a_card.abroad,
               });
               this.setState({
                    acard_id: res.acard_id,
                    acard_no: res.acard_no,
               });
               if (fileImage) {
                    let formData = new FormData();
                    formData.append("acard_id", res.acard_id);
                    formData.append("file", fileImage);
                    await POST("/aos/create_image_acard", formData, true);
               }
               swal({
                    icon: "success",
                    text: "เพิ่ม A-Card ในรายการเรียบร้อยแล้ว",
                    buttons: {
                         next: "ดำเนินการจองรถ",
                         submit: "เสร็จสิ้น",
                    },
               }).then((value) => {
                    // console.log(`value`, value);
                    if (value === "next") {
                         this.onClickBooking();
                    } else {
                         window.location.assign("/admin/a_card");
                    }
               });
               this.setState({ loading: false });
          } catch (error) {
               swal({
                    icon: "warning",
                    text: `ไม่สามารถทำรายการได้ ${error}`,
                    buttons: {
                         danger: "ยกเลิก",
                    },
               });
               this.setState({ loading: false });
          }
     };

     postUpdate = async () => {
          this.setState({ loading: true });
          let { a_card, fileImage } = this.state;
          try {
               let res = await POST("/aos/update_acard", {
                    userinfo_idcard: a_card.userinfo_idcard,
                    userinfo_address: a_card.userinfo_address,
                    acard_id: Number(a_card.acard_id),
                    acard_saler: a_card.acard_saler,
                    branch_id: Number(a_card.branch_id),
                    acard_create: dayjs(a_card.acard_create).format("YYYY-MM-DD HH:mm:ss"),
                    // acard_no: a_card.acard_no,
                    acard_gms: a_card.acard_gms,
                    acard_from: a_card.acard_from,
                    acard_customer_type: a_card.acard_customer_type,
                    acard_prefix: a_card.acard_prefix,
                    acard_firstname: a_card.acard_firstname,
                    acard_lastname: a_card.acard_lastname,
                    acard_gender: a_card.acard_gender,
                    acard_birthdate: dayjs(a_card.acard_birthdate).format("YYYY-MM-DD HH:mm:ss"),
                    acard_tel: a_card.acard_tel,
                    acard_line: a_card.acard_line,
                    acard_province: a_card.acard_province,
                    acard_district: a_card.acard_district,
                    acard_sub_district: a_card.abroad === "ใน" ? a_card.acard_sub_district : "-",
                    acard_zipcode: a_card.acard_zipcode,
                    status: a_card.status,
                    acard_test: a_card.acard_test,
                    acard_test_date: dayjs(a_card.acard_test_date).format("YYYY-MM-DD HH:mm:ss"),
                    acard_pdpa: a_card.acard_pdpa,
                    bank_name: a_card.bank_name,
                    bank_number: a_card.bank_number,
                    account_bank: a_card.account_bank,
                    book_bank: a_card.book_bank,
                    series_id: a_card.series_id,
                    model_id: a_card.model_id,
                    color_id: a_card.color_id,
                    abroad: a_card.abroad,
               });
               if (fileImage) {
                    let formData = new FormData();
                    formData.append("acard_id", a_card.acard_id);
                    formData.append("file", fileImage);
                    await POST("/aos/create_image_acard", formData, true);
               }
               swal({
                    text: "บันทึกข้อมูลแล้ว",
                    icon: "success",
                    buttons: {
                         submit: "เสร็จสิ้น",
                    },
               }).then((value) => {
                    window.location.reload();
               });
               this.setState({ loading: false });
          } catch (error) {
               swal({
                    icon: "warning",
                    text: `ไม่สามารถทำรายการได้ ${error}`,
                    buttons: {
                         danger: "ยกเลิก",
                    },
               });
               this.setState({ loading: false });
          }
     };

     /* -------------------------- Handle -------------------------- */
     handleConfirm = async (event) => {
          let { a_card } = this.state;
          event.preventDefault();
          const form = event.currentTarget;

          if (form.checkValidity() === false) {
               swal({
                    text: "กรุณากรอกข้อมูลให้ครบถ้วน",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               event.preventDefault();
               event.stopPropagation();
               this.setState({
                    validated: true,
               });
          } else {
               event.preventDefault();
               this.setState({
                    validated: true,
               });
               this.postData();
          }
     };

     handleEdit = async (event) => {
          let { a_card } = this.state;
          const form = event.currentTarget;
          event.preventDefault();

          if (form.checkValidity() === false) {
               swal({
                    text: "กรุณากรอกข้อมูลให้ครบถ้วน",
                    icon: "warning",
                    button: "ตกลง",
                    dangerMode: true,
               });
               event.preventDefault();
               event.stopPropagation();
          } else {
               event.preventDefault();
               this.setState({
                    validated: true,
               });
               this.postUpdate();
          }
     };
     handleDelete = async () => {
          const { a_card } = this.state;
          try {
               let res = await POST("/aos/del_acard", { acard_id: Number(a_card.acard_id) });
               // console.log(res);
               if (res === "del acard success") {
                    window.location.replace("/admin/a_card/list");
               }
          } catch (error) {
               // console.log(error);
          }
     };

     viewDocument = () => {
          window.open(ip + "/static/" + this.state.placeholder + `?time=${new Date()}`);
          // this.setState({ showImage: !this.state.showImage });
     };

     handleReset = async () => {
          let { a_card } = this.state;
          a_card.acard_saler = "";
          a_card.branch_id = "";
          a_card.acard_create = null;
          // a_card.acard_no = "";
          a_card.acard_from = "";
          a_card.acard_customer_type = "";
          a_card.acard_prefix = "";
          a_card.acard_firstname = "";
          a_card.acard_lastname = "";
          a_card.acard_gender = "";
          a_card.acard_birthdate = null;
          a_card.acard_tel = "";
          a_card.acard_line = "";
          a_card.acard_province = "";
          a_card.acard_district = "";
          a_card.acard_sub_district = "";
          a_card.acard_zipcode = "";
          a_card.status = "";
          a_card.acard_test = "";
          a_card.acard_test_date = null;
          a_card.acard_pdpa = "";
          a_card.abroad = "ใน";
          this.setState({
               a_card,
          });
     };

     handleChangeText = ({ target: { name, value } }) => {
          let { a_card } = this.state;
          a_card[name] = value;
          if (name === "acard_province" && a_card.abroad === "ใน") {
               a_card[name] = value;
               a_card.acard_district = "";
               a_card.acard_sub_district = "";
               a_card.acard_zipcode = "";
               this.getAddress(a_card.acard_province);
          } else if (name === "acard_district" && a_card.abroad === "ใน") {
               a_card.acard_sub_district = "";
               a_card.acard_zipcode = "";
               this.getAddress(a_card.acard_province, a_card.acard_district);
          } else if (name === "acard_sub_district" && a_card.abroad === "ใน") {
               a_card.acard_zipcode = "";
               this.getAddress(a_card.acard_province, a_card.acard_district, a_card.acard_sub_district);
          } else if (name === "series_id") {
               this.getModel(value);
               a_card.model_id = "";
               a_card.color_id = "";
               a_card.model_code = "";
          } else if (name === "model_id") {
               this.getColors(value);
               a_card.color_id = "";
               a_card.model_code = "";
               let modelSelect = this.state.model.find((e) => {
                    if (Number(e.model_id) === Number(value)) {
                         return e.model_code;
                    }
               });
               a_card.model_code = modelSelect?.model_code;
          } else if (name === "acard_prefix") {
               if (value === "นาย") {
                    a_card.acard_gender = "ชาย";
               } else if (value === "นาง" || value === "นางสาว") {
                    a_card.acard_gender = "หญิง";
               }
          }
          this.setState({ a_card });
     };

     handleSelectDate = (date, dateString, name) => {
          let { a_card, dateSelect } = this.state;
          a_card[name] = date;
          dateSelect[name] = date;
          this.setState({ a_card, dateSelect });
     };

     handleChangeNumber = (e, name) => {
          let { a_card } = this.state;
          a_card[name] = e.value;
          this.setState({ a_card });
     };

     handleChangeAutocomplete = async (e) => {
          let { seller, a_card } = this.state;
          let seller_name = e[0];
          let user_id = seller.find((el) => el.name === seller_name)?.user_id;
          // let branch_id = this.state.branch.find((el) => e === el.branch_id);

          if (!seller_name) {
               user_id = seller.find((el) => el.name === a_card?.acard_saler)?.user_id;
          }

          this.handleChangeText({ target: { name: "acard_saler", value: user_id } });
     };

     handleChangeAutocompleteName = async (e) => {
          let { a_card, acard_result } = this.state;
          let id;
          a_card.acard_firstname = e[0];
          if (acard_result.find((el) => el.acard_firstname === e[0])) {
               id = acard_result.find((el) => el.acard_firstname === e[0])?.acard_id;
               this.getAcardInfo(id);
          }
     };

     handleOnInputChange = (value) => {
          let { seller } = this.state;
          let user_id = seller.find((el) => el.name === value)?.user_id;
          this.handleChangeText({ target: { name: "acard_saler", value: user_id } });
     };

     handleOnInputChangeName = (value) => {
          this.handleChangeText({ target: { name: "acard_firstname", value: value } });
     };

     onClickAddSuccess = () => {
          const { acard_id } = this.state;
          this.props.history.push({ pathname: "/admin/a_card/manage/edit", state: { acard_id: acard_id } });
          window.location.reload();
     };

     onClickBooking = () => {
          const { a_card, acard_no } = this.state;
          let userinfo_name;
          if (a_card.acard_customer_type === "นิติบุคคล") {
               userinfo_name = a_card.acard_firstname;
          } else {
               userinfo_name = `${a_card.acard_firstname} ${a_card.acard_lastname}`;
          }
          this.props.history.push({
               pathname: "/admin/order/car/detail/add",
               state: {
                    order: {
                         pass_acard: true,
                         //                saler_name: a_card.acard_saler,
                         //                userinfo_a_card: acard_no,
                         //                userinfo_name: userinfo_name,
                         //                userinfo_tel: a_card.acard_tel,
                         //                userinfo_line: a_card.acard_line,
                         //                userinfo_birth_date: a_card.acard_birthdate,
                         //                userinfo_province: a_card.acard_province,
                         //                userinfo_district: a_card.acard_district,
                         //                userinfo_sub_district: a_card.acard_sub_district,
                         //                userinfo_zipcode: a_card.acard_zipcode,
                         //                userinfo_idcard: a_card.userinfo_idcard,
                         //                userinfo_address: a_card.userinfo_address,
                         //                bank_name: a_card.bank_name,
                         //                userinfo_prefix: a_card.acard_prefix,
                         //                // bank_number: a_card.bank_number,
                         //                // account_bank: a_card.account_bank,
                         //                // book_bank: a_card.book_bank,
                         //                series_id: a_card.series_id,
                         //                model_id: a_card.model_id,
                         //                color_id: a_card.color_id,
                         //                userinfo_customer_type: a_card.acard_customer_type,
                    },
               },
          });
          localStorage.removeItem("orderObject");
          console.log(`userinfo_name`, userinfo_name);
          let orderObject = {};
          orderObject["branch_id"] = a_card.branch_id;
          orderObject["saler_name"] = a_card.acard_saler;
          orderObject["userinfo_a_card"] = acard_no;
          orderObject["userinfo_name"] = userinfo_name;
          orderObject["userinfo_tel"] = a_card.acard_tel;
          orderObject["userinfo_line"] = a_card.acard_line;
          orderObject["userinfo_birth_date"] = a_card.acard_birthdate;
          orderObject["userinfo_province"] = a_card.acard_province;
          orderObject["userinfo_district"] = a_card.acard_district;
          orderObject["userinfo_sub_district"] = a_card.acard_sub_district ? a_card.acard_sub_district : "-";
          orderObject["userinfo_zipcode"] = a_card.acard_zipcode;
          orderObject["userinfo_idcard"] = a_card.userinfo_idcard;
          orderObject["userinfo_address"] = a_card.userinfo_address;
          orderObject["bank_name"] = a_card.bank_name;
          orderObject["userinfo_prefix"] = a_card.acard_prefix;
          orderObject["series_id"] = a_card.series_id;
          orderObject["model_id"] = a_card.model_id;
          orderObject["color_id"] = a_card.color_id;
          orderObject["userinfo_customer_type"] = a_card.acard_customer_type;
          orderObject["abroad"] = a_card.abroad;
          console.log(`orderObject`, orderObject);
          localStorage.setItem("orderObject", JSON.stringify(orderObject));
     };
     onClickEditSuccess = () => {
          this.setState({
               show_modal_edit_success: false,
          });
     };

     handleChangeFile = (e) => {
          let { a_card, placeholder } = this.state;
          let fileUploaded = e.target.files;
          // if (event.target.files[0]) {
          //      const fileUploaded = event.target.files[0];
          //      this.setState({
          //           file: fileUploaded,
          //           fileTextHolder: fileUploaded.name,
          //      });
          // }
          if (e.target.files && e.target.files[0]) {
               // let reader = new FileReader();
               // reader.onload = (e) => {
               //      a_card.book_bank = e.target.result.split(",")[1];
               //      this.setState({ a_card });
               // };
               // reader.readAsDataURL(e.target.files[0]);
               this.setState({
                    fileImage: e.target.files[0],
               });
          }

          if (fileUploaded[0] && fileUploaded[0].name) {
               let str = fileUploaded[0].name;
               if (str.length >= 15) {
                    placeholder = str.substring(0, 15) + "...";
               } else {
                    placeholder = str;
               }
          }

          this.setState({ a_card, placeholder, showImage: false, hidenImg: true });
     };

     render() {
          let { title, validated, a_card, previous_path, branch, province, district, sub_district, zip_code, user, seller, dateSelect, loading } =
               this.state;
          // let customer_type = ["บุคคลทั่วไป", "นิติบุคคล", "Fleet"];
          let customer_type = ["บุคคลทั่วไป", "นิติบุคคล"];
          let prefix = [
               "นาย",
               "นางสาว",
               "นาง",
               "พล.ต.อ.",
               "พล.ต.อ. หญิง",
               "พล.ต.ท",
               "พล.ต.ท หญิง",
               "พล.ต.ต",
               "พล.ต.ต หญิง",
               "พ.ต.อ.",
               "พ.ต.อ. หญิง",
               "พ.ต.อ.(พิเศษ)",
               "พ.ต.อ.(พิเศษ) หญิง",
               "พ.ต.ท.",
               "พ.ต.ท. หญิง",
               "พ.ต.ต.",
               "พ.ต.ต. หญิง",
               "ร.ต.อ.",
               "ร.ต.อ. หญิง",
               "ร.ต.ท.",
               "ร.ต.ท. หญิง",
               "ร.ต.ต.",
               "ร.ต.ต. หญิง",
               "ด.ต.",
               "ด.ต. หญิง",
               "ส.ต.อ.",
               "ส.ต.อ. หญิง",
               "ส.ต.ท.",
               "ส.ต.ท. หญิง",
               "ส.ต.ต.",
               "ส.ต.ต. หญิง",
               "จ.ส.ต.",
               "จ.ส.ต. หญิง",
               "พลฯ",
               "พลฯ หญิง",
               "พล.อ.",
               "พล.อ. หญิง",
               "พล.ท.",
               "พล.ท. หญิง",
               "พล.ต.",
               "พล.ต.หญิง",
               "พ.อ.",
               "พ.อ.หญิง",
               "พ.อ.(พิเศษ)",
               "พ.อ.(พิเศษ) หญิง",
               "พ.ท.",
               "พ.ท.หญิง",
               "พ.ต.",
               "พ.ต.หญิง",
               "ร.อ.",
               "ร.อ.หญิง",
               "ร.ท.",
               "ร.ท.หญิง",
               "ร.ต.",
               "ร.ต.หญิง",
               "ส.อ.",
               "ส.อ.หญิง",
               "ส.ท.",
               "ส.ท.หญิง",
               "ส.ต.",
               "ส.ต.หญิง",
               "จ.ส.อ.",
               "จ.ส.อ.หญิง",
               "จ.ส.ท.",
               "จ.ส.ท.หญิง",
               "จ.ส.ต.",
               "จ.ส.ต.หญิง",
               "พลฯ",
               "ว่าที่ พ.ต.",
               "ว่าที่ พ.ต. หญิง",
               "ว่าที่ ร.อ.",
               "ว่าที่ ร.อ. หญิง",
               "ว่าที่ ร.ท.",
               "ว่าที่ ร.ท. หญิง",
               "ว่าที่ ร.ต.",
               "ว่าที่ ร.ต. หญิง",
               "พล.ร.อ.",
               "พล.ร.อ.หญิง",
               "พล.ร.ท.",
               "พล.ร.ท.หญิง",
               "พล.ร.ต.",
               "พล.ร.ต.หญิง",
               "น.อ.",
               "น.อ.หญิง",
               "น.อ.(พิเศษ)",
               "น.อ.(พิเศษ) หญิง",
               "น.ท.",
               "น.ท.หญิง",
               "น.ต.",
               "น.ต.หญิง",
               "ร.อ.",
               "ร.อ.หญิง",
               "ร.ท.",
               "ร.ท.หญิง",
               "ร.ต.",
               "ร.ต.หญิง",
               "พ.จ.อ.",
               "พ.จ.อ.หญิง",
               "พ.จ.ท.",
               "พ.จ.ท.หญิง",
               "พ.จ.ต.",
               "พ.จ.ต.หญิง",
               "จ.อ.",
               "จ.อ.หญิง",
               "จ.ท.",
               "จ.ท.หญิง",
               "จ.ต.",
               "จ.ต.หญิง",
               "พลฯ",
               "พล.อ.อ.",
               "พล.อ.อ.หญิง",
               "พล.อ.ท.",
               "พล.อ.ท.หญิง",
               "พล.อ.ต.",
               "พล.อ.ต.หญิง",
               "น.อ.",
               "น.อ.หญิง",
               "น.อ.(พิเศษ)",
               "น.อ.(พิเศษ) หญิง",
               "น.ท.",
               "น.ท.หญิง",
               "น.ต.",
               "น.ต.หญิง",
               "ร.อ.",
               "ร.อ.หญิง",
               "ร.ท.",
               "ร.ท.หญิง",
               "ร.ต.",
               "ร.ต.หญิง",
               "พ.อ.อ.",
               "พ.อ.อ.หญิง",
               "พ.อ.ท.",
               "พ.อ.ท.หญิง",
               "พ.อ.ต.",
               "พ.อ.ต.หญิง",
               "จ.อ.",
               "จ.อ.หญิง",
               "จ.ท.",
               "จ.ท.หญิง",
               "จ.ต.",
               "จ.ต.หญิง",
               "พลฯ",
               "หม่อม",
               "ม.จ.",
               "ม.ร.ว.",
               "ม.ล.",
               "ดร.",
               "ศ.ดร.",
               "ศ.",
               "ผศ.ดร.",
               "ผศ.",
               "รศ.ดร.",
               "รศ.",
               "นพ.",
               "พญ.",
               "นสพ.",
               "สพญ.",
               "ทพ.",
               "ทพญ.",
               "ภก.",
               "ภกญ.",
               "พระ",
               "พระครู",
               "พระมหา",
               "พระสมุห์",
               "พระอธิการ",
               "สามเณร",
               "แม่ชี",
               "บาทหลวง",
               "Mr.",
               "Mrs.",
               "Ms.",
               "Miss",
               "Dr.",
          ];
          let acard_from = [
               "ลูกค้าเก่า (ส่วนตัว)",
               "ลูกค้าทั่วไป (ออกตลาดเอง)",
               "ช่องทางออนไลน์ (ส่วนตัว)",
               "ลูกค้าเก่า TLT",
               "Walk-In",
               "Booth/กิจกรรมภายนอก",
               "ศูนย์บริการ (ภายใน)",
               "ลูกค้าแนะนำ",
               "Line/Facebook/Website (ของบริษัท)",
          ];
          return (
               <div className="panel">
                    <ScrollToTopOnMount />
                    {title ? <Breadcrumbs title={title} active={title.length - 1} button={""} /> : null}
                    {this.state.loading_data ? (
                         <Loader />
                    ) : (
                         <Form noValidate validated={validated} onSubmit={this.handleConfirm}>
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>ข้อมูล A-Card</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  <Form.Row>
                                                       {a_card.acard_saler_name && a_card.acard_saler_name ? (
                                                            <Field
                                                                 handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                                 type={"autocomplete"}
                                                                 title={"ชื่อผู้ขาย (Sales)"}
                                                                 placeholder={a_card.acard_saler_name}
                                                                 require={true}
                                                                 validated={validated}
                                                                 md={6}
                                                                 value={a_card ? a_card.acard_saler_name : ""}
                                                                 handleOnInputChange={this.handleOnInputChange}
                                                                 option={this.state.seller.map((el) => el.name || "-")}
                                                            />
                                                       ) : null}
                                                       {!a_card.acard_saler_name && (
                                                            <Field
                                                                 handleChangeAutocomplete={this.handleChangeAutocomplete}
                                                                 type={"autocomplete"}
                                                                 title={"ชื่อผู้ขาย (Sales)"}
                                                                 placeholder={
                                                                      seller.find((el) => el.user_id === a_card.acard_saler)?.name
                                                                           ? seller.find((el) => el.user_id === a_card.acard_saler)?.name
                                                                           : "กรุณากรอกชื่อผู้ขาย (Sales)"
                                                                 }
                                                                 validated={validated}
                                                                 require={true}
                                                                 md={6}
                                                                 value={a_card ? a_card.acard_saler : ""}
                                                                 handleOnInputChange={this.handleOnInputChange}
                                                                 option={this.state.seller.map((el) => el.name || "-")}
                                                            />
                                                       )}
                                                       <Field
                                                            type={"select"}
                                                            title={"สาขา Sales (Branch)"}
                                                            name={"branch_id"}
                                                            value={a_card ? a_card.branch_id : ""}
                                                            placeholder={"กรุณาเลือกประเภทลูกค้า"}
                                                            option={branch?.map((e) => ({ value: e.branch_id, name: e.branch_name }))}
                                                            require={true}
                                                            validated={validated}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"date"}
                                                            title={"วันที่สร้าง A-Card (Date)"}
                                                            name={"acard_create"}
                                                            value={dateSelect.acard_create ? dayjs(dateSelect.acard_create) : null}
                                                            validated={validated}
                                                            placeholder={"เลือกวันที่"}
                                                            formatDate={"DD/MM/YYYY"}
                                                            require={true}
                                                            md={3}
                                                            handleSelectDate={this.handleSelectDate}
                                                       />
                                                  </Form.Row>
                                                  <Form.Row>
                                                       {/* <Field type={"text"} title={"A-Card No."} name={"acard_no"} value={a_card ? a_card.acard_no : ""} placeholder={"กรุณากรอก A-Card No."} require={true} md={6} handleChangeText={this.handleChangeText} /> */}
                                                       <Field
                                                            type={"text"}
                                                            title={"A-Card GMS"}
                                                            name={"acard_gms"}
                                                            value={a_card ? a_card.acard_gms : ""}
                                                            placeholder={"กรุณากรอก A-Card GMS"}
                                                            require={true}
                                                            md={6}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"select"}
                                                            title={"ที่มาของ A-Card"}
                                                            name={"acard_from"}
                                                            value={a_card ? a_card.acard_from : ""}
                                                            placeholder={"เลื่อกที่มาของ A-Card"}
                                                            option={acard_from?.map((e) => ({ value: e, name: e }))}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Form.Row>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>ข้อมูลทั่วไป</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  <Form.Row>
                                                       <Field
                                                            type={"select"}
                                                            title={"ประเภทลูกค้า"}
                                                            name={"acard_customer_type"}
                                                            value={a_card ? a_card.acard_customer_type : ""}
                                                            placeholder={"กรุณาเลือกประเภทลูกค้า"}
                                                            option={customer_type?.map((e) => ({ value: e, name: e }))}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       {(a_card.acard_customer_type === "บุคคลทั่วไป" ||
                                                            a_card.acard_customer_type === "Fleet" ||
                                                            !a_card.acard_customer_type) && (
                                                            <>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"คำนำหน้า"}
                                                                      name={"acard_prefix"}
                                                                      value={a_card ? a_card.acard_prefix : ""}
                                                                      placeholder={"กรุณาเลือกคำนำหน้า"}
                                                                      option={prefix?.map((e) => ({ value: e, name: e }))}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      handleChangeAutocomplete={this.handleChangeAutocompleteName}
                                                                      type={"autocomplete"}
                                                                      title={"ชื่อลูกค้า"}
                                                                      placeholder={a_card?.acard_firstname || "กรุณากรอกชื่อลูกค้า"}
                                                                      require={true}
                                                                      validated={validated}
                                                                      md={3}
                                                                      value={a_card?.acard_firstname || ""}
                                                                      handleOnInputChange={this.handleOnInputChangeName}
                                                                      option={this.state.name_normal.map((el) => el.acard_firstname) || "-"}
                                                                 />
                                                                 {/* <Field
                                                                 type={'text'}
                                                                 title={'ชื่อลูกค้า'}
                                                                 name={'acard_firstname'}
                                                                 value={a_card ? a_card.acard_firstname : ''}
                                                                 placeholder={'กรุณากรอกชื่อลูกค้า'}
                                                                 require={true}
                                                                 md={3}
                                                                 handleChangeText={this.handleChangeText}
                                                            /> */}
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"นามสกุลลูกค้า"}
                                                                      name={"acard_lastname"}
                                                                      value={a_card ? a_card.acard_lastname : ""}
                                                                      placeholder={"กรุณากรอกนามสกุลลูกค้า"}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </>
                                                       )}
                                                       {a_card.acard_customer_type === "นิติบุคคล" && (
                                                            <>
                                                                 {/* <Field
                                                                 type={'text'}
                                                                 title={'ชื่อ ห้าง/ร้าน/บริษัท'}
                                                                 name={'acard_firstname'}
                                                                 value={a_card ? a_card.acard_firstname : ''}
                                                                 placeholder={'กรุณากรอกชื่อ ห้าง/ร้าน/บริษัท'}
                                                                 require={true}
                                                                 md={9}
                                                                 handleChangeText={this.handleChangeText}
                                                            /> */}
                                                                 <Field
                                                                      handleChangeAutocomplete={this.handleChangeAutocompleteName}
                                                                      type={"autocomplete"}
                                                                      title={"ชื่อ ห้าง/ร้าน/บริษัท (พร้อมสาขา)"}
                                                                      placeholder={"กรุณากรอกชื่อ ห้าง/ร้าน/บริษัท"}
                                                                      require={true}
                                                                      validated={validated}
                                                                      md={9}
                                                                      value={a_card ? a_card.acard_firstname : ""}
                                                                      handleOnInputChange={this.handleOnInputChangeName}
                                                                      option={this.state.name_business.map((el) => el.acard_firstname || "-")}
                                                                 />
                                                            </>
                                                       )}
                                                  </Form.Row>
                                                  <Form.Row>
                                                       {(a_card.acard_customer_type === "บุคคลทั่วไป" ||
                                                            a_card.acard_customer_type === "Fleet" ||
                                                            !a_card.acard_customer_type) && (
                                                            <>
                                                                 <Col lg={3} md={3}>
                                                                      <div className="input-auto">
                                                                           <LabelBar
                                                                                type={"radio"}
                                                                                name={"acard_gender"}
                                                                                title={"เพศ "}
                                                                                label={["ชาย", "หญิง"]}
                                                                                value={a_card.acard_gender ? a_card.acard_gender : ""}
                                                                                style_id={"__inline"}
                                                                                handleChangeText={this.handleChangeText}
                                                                           />
                                                                      </div>
                                                                 </Col>
                                                                 <Field
                                                                      type={"date"}
                                                                      title={"วันเกิด"}
                                                                      name={"acard_birthdate"}
                                                                      value={dateSelect.acard_birthdate ? dateSelect.acard_birthdate : ""}
                                                                      placeholder={"เลือกวันที่"}
                                                                      require={true}
                                                                      validated={validated}
                                                                      formatDate={"DD/MM/YYYY"}
                                                                      md={3}
                                                                      handleSelectDate={this.handleSelectDate}
                                                                 />
                                                                 <Field
                                                                      id={"field-id-card"}
                                                                      // type={"number"}
                                                                      type={"text"}
                                                                      title={"หมายเลขประจำตัว"}
                                                                      name={"userinfo_idcard"}
                                                                      value={a_card?.userinfo_idcard}
                                                                      placeholder={"กรุณากรอกหมายเลขประจำตัว"}
                                                                      require={true}
                                                                      // format="#-####-#####-##-#"
                                                                      // mask="X"
                                                                      // typeInput={"id"}
                                                                      // handleChangeNumber={(e) => this.handleChangeNumber(e, "userinfo_idcard")}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </>
                                                       )}
                                                       {a_card.acard_customer_type === "นิติบุคคล" && (
                                                            <>
                                                                 <Field
                                                                      id={"field-id-card"}
                                                                      type={"number"}
                                                                      title={"หมายเลขประจำตัวผู้เสียภาษี"}
                                                                      name={"userinfo_idcard"}
                                                                      value={a_card?.userinfo_idcard}
                                                                      placeholder={"กรุณากรอกหมายเลขประจำตัวผู้เสียภาษี"}
                                                                      format="#-####-#####-##-#"
                                                                      mask="X"
                                                                      md={6}
                                                                      require={true}
                                                                      typeInput={"id"}
                                                                      handleChangeNumber={(e) => this.handleChangeNumber(e, "userinfo_idcard")}
                                                                 />
                                                                 <Field
                                                                      type={"date"}
                                                                      title={"วันจดทะเบียนพาณิชย์"}
                                                                      name={"acard_birthdate"}
                                                                      value={dateSelect.acard_birthdate ? dateSelect.acard_birthdate : ""}
                                                                      placeholder={"เลือกวันที่"}
                                                                      require={true}
                                                                      validated={validated}
                                                                      formatDate={"DD/MM/YYYY"}
                                                                      md={3}
                                                                      handleSelectDate={this.handleSelectDate}
                                                                 />
                                                            </>
                                                       )}
                                                       <Field
                                                            type={"text"}
                                                            title={"หมายเลขโทรศัพท์"}
                                                            name={"acard_tel"}
                                                            value={a_card ? a_card.acard_tel : ""}
                                                            placeholder={"กรุณากรอกหมายเลขโทรศัพท์"}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Form.Row>
                                                  <Form.Row>
                                                       <Field
                                                            type={"text"}
                                                            title={"Line ID"}
                                                            name={"acard_line"}
                                                            value={a_card ? a_card.acard_line : ""}
                                                            placeholder={"กรุณากรอก Line ID"}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </Form.Row>
                                                  <Form.Row>
                                                       <Col lg={3} md={3}>
                                                            <div className="input-auto">
                                                                 <LabelBar
                                                                      type={"radio"}
                                                                      name={"abroad"}
                                                                      title={"ประเทศ "}
                                                                      label={["ใน", "นอก"]}
                                                                      value={a_card.abroad ? a_card.abroad : ""}
                                                                      style_id={"__inline"}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </div>
                                                       </Col>
                                                       <Field
                                                            type={"text"}
                                                            title={"ที่อยู่"}
                                                            name={"userinfo_address"}
                                                            value={a_card ? a_card.userinfo_address : ""}
                                                            placeholder={"กรุณากรอกที่อยู่"}
                                                            require={true}
                                                            md={a_card.abroad === "ใน" ? 3 : 9}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       {a_card.abroad === "ใน" ? (
                                                            <>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"จังหวัด"}
                                                                      name={"acard_province"}
                                                                      value={a_card ? a_card.acard_province : ""}
                                                                      placeholder={"กรุณาเลือกจังหวัด"}
                                                                      option={province?.map((e) => ({ value: e.province, name: e.province }))}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"อำเภอ/เขต"}
                                                                      name={"acard_district"}
                                                                      value={a_card ? a_card.acard_district : ""}
                                                                      placeholder={"กรุณาเลือกอำเภอ/เขต"}
                                                                      option={district?.map((e) => ({ value: e.district, name: e.district }))}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </>
                                                       ) : null}
                                                  </Form.Row>
                                                  <Form.Row>
                                                       {a_card.abroad === "ใน" ? (
                                                            <>
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"ตำบล/แขวง"}
                                                                      name={"acard_sub_district"}
                                                                      value={a_card ? a_card.acard_sub_district : ""}
                                                                      placeholder={"กรุณาเลือกตำบล/แขวง"}
                                                                      option={sub_district?.map((e) => ({
                                                                           value: e.sub_district,
                                                                           name: e.sub_district,
                                                                      }))}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"select"}
                                                                      title={"หมายเลขไปรษณีย์"}
                                                                      name={"acard_zipcode"}
                                                                      value={a_card ? a_card.acard_zipcode : ""}
                                                                      placeholder={"กรุณากรอกหมายเลขไปรษณีย์"}
                                                                      option={zip_code?.map((e) => ({ value: e, name: e }))}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </>
                                                       ) : (
                                                            <>
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"ถนน (อำเภอ/เขต)"}
                                                                      name={"acard_district"}
                                                                      value={a_card ? a_card.acard_district : ""}
                                                                      placeholder={"กรุณากรอกถนน (อำเภอ/เขต)"}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"เมือง (จังหวัด)"}
                                                                      name={"acard_province"}
                                                                      value={a_card ? a_card.acard_province : ""}
                                                                      placeholder={"กรุณากรอกเมือง (จังหวัด)"}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                                 <Field
                                                                      type={"text"}
                                                                      title={"หมายเลขไปรษณีย์"}
                                                                      name={"acard_zipcode"}
                                                                      value={a_card ? a_card.acard_zipcode : ""}
                                                                      placeholder={"กรุณากรอกหมายเลขไปรษณีย์"}
                                                                      require={true}
                                                                      md={3}
                                                                      handleChangeText={this.handleChangeText}
                                                                 />
                                                            </>
                                                       )}
                                                  </Form.Row>
                                                  <Form.Row>
                                                       <Field
                                                            type={"text"}
                                                            title={"ธนาคาร"}
                                                            name={"bank_name"}
                                                            value={a_card ? a_card.bank_name : ""}
                                                            placeholder={"กรุณากรอกธนาคาร"}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"number"}
                                                            title={"เลขที่บัญชี"}
                                                            name={"bank_number"}
                                                            value={a_card ? a_card.bank_number : ""}
                                                            placeholder={"กรุณากรอกเลขที่บัญชี"}
                                                            require={true}
                                                            md={3}
                                                            format="###-#-#####-#"
                                                            mask="X"
                                                            // require={true}
                                                            handleChangeNumber={(e) => this.handleChangeNumber(e, "bank_number")}
                                                       />
                                                       <Field
                                                            type={"text"}
                                                            title={"ชื่อบัญชี"}
                                                            name={"account_bank"}
                                                            value={a_card ? a_card.account_bank : ""}
                                                            placeholder={"กรุณากรอกชื่อบัญชี"}
                                                            require={true}
                                                            md={3}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                       <Field
                                                            type={"file"}
                                                            placeholder={this.state.placeholder}
                                                            value={null}
                                                            button={"แนบไฟล์"}
                                                            style_id={"custom-btn __beside-btn-nohight"}
                                                            handleChange={(e) => this.handleChangeFile(e)}
                                                       />
                                                  </Form.Row>
                                                  {previous_path === "edit" && a_card.book_bank && (
                                                       <Form.Row>
                                                            <div className="button-group __submit row">
                                                                 <Col xs={6}>
                                                                      <Button md={6} variant={"success"} onClick={this.viewDocument}>
                                                                           ดูเอกสาร
                                                                      </Button>
                                                                 </Col>
                                                                 {/* {this.state.showImage === true && (
                                                                 <Image
                                                                      width={400}
                                                                      height={400}
                                                                      style={{ marginTop: 20 }}
                                                                      src={ip + a_card.book_bank + `?time=${new Date()}`}
                                                                 />
                                                            )} */}
                                                            </div>
                                                       </Form.Row>
                                                  )}
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>รายละเอียดรถยนต์ที่ลูกค้าสนใจ</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  <div>
                                                       <Form.Row>
                                                            <Field
                                                                 type={"select"}
                                                                 title={"Serie"}
                                                                 name={"series_id"}
                                                                 placeholder={"กรุณาเลือก Serie"}
                                                                 option={this.state.series.map((e) => ({
                                                                      value: e.series_id,
                                                                      name: e.series_name,
                                                                 }))}
                                                                 value={a_card?.series_id}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"select"}
                                                                 title={"Model"}
                                                                 name={"model_id"}
                                                                 placeholder={"กรุณาเลือก Model"}
                                                                 option={this.state.model?.map((e) => ({
                                                                      value: e.model_id,
                                                                      name: e.model_name,
                                                                 }))}
                                                                 value={a_card?.model_id}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                       </Form.Row>
                                                       <Form.Row>
                                                            <Field
                                                                 type={"select"}
                                                                 title={"สีที่สนใจ"}
                                                                 name={"color_id"}
                                                                 placeholder={"กรุณาเลือกสีที่สนใจ"}
                                                                 option={this.state.color.map((e) => ({
                                                                      value: e.color_id,
                                                                      name: e.color_name,
                                                                 }))}
                                                                 value={a_card?.color_id}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"text"}
                                                                 title={"Model Code"}
                                                                 name={"model_code"}
                                                                 placeholder={"กรุณากรอก Model Code"}
                                                                 value={a_card?.model_code}
                                                                 require={true}
                                                                 md={6}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                       </Form.Row>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        <div className="-header">
                                             <div>สถานะ A-Card</div>
                                        </div>
                                        <div className="-body">
                                             <div className="content">
                                                  <div className="input-auto">
                                                       <LabelBar
                                                            type={"radio"}
                                                            name={"status"}
                                                            title={"สถานะ  A-Card  "}
                                                            label={["Hot", "Warm", "Cool"]}
                                                            value={a_card ? a_card.status : ""}
                                                            require={true}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </div>

                                                  <div className="input-auto">
                                                       <Form.Group>
                                                            <LabelBar
                                                                 type={"radio"}
                                                                 name={"acard_test"}
                                                                 title={"ทดลองขับ  "}
                                                                 label={["ไม่ทดลองขับ", "ทดลองขับ"]}
                                                                 require={true}
                                                                 value={a_card ? a_card.acard_test : ""}
                                                                 handleChangeText={this.handleChangeText}
                                                            />
                                                            <Field
                                                                 type={"date"}
                                                                 title={""}
                                                                 name={"acard_test_date"}
                                                                 value={dateSelect.acard_test_date ? dateSelect.acard_test_date : ""}
                                                                 placeholder={"เลือกวันที่ทดลองขับ "}
                                                                 require={a_card?.acard_test === "ทดลองขับ" ? true : false}
                                                                 validated={a_card?.acard_test === "ทดลองขับ" ? validated : false}
                                                                 md={4}
                                                                 formatDate={"DD/MM/YYYY"}
                                                                 disabled={a_card?.acard_test !== "ทดลองขับ"}
                                                                 handleSelectDate={this.handleSelectDate}
                                                            />
                                                       </Form.Group>
                                                  </div>

                                                  <div className="input-auto">
                                                       <LabelBar
                                                            type={"radio"}
                                                            name={"acard_pdpa"}
                                                            title={"PDPA  ยอบรับข่าวสาร"}
                                                            label={["ยอบรับข่าวสาร", "ไม่รับข่าวสาร"]}
                                                            require={true}
                                                            value={a_card ? a_card.acard_pdpa : ""}
                                                            handleChangeText={this.handleChangeText}
                                                       />
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="input-field">
                                        {previous_path === "add" && (
                                             <div className="button-group __submit row">
                                                  <Col xs={6}>
                                                       {loading ? (
                                                            <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                                 <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                                 Loading...
                                                            </Button>
                                                       ) : (
                                                            <Button type="submit" disabled={loading}>
                                                                 Submit
                                                            </Button>
                                                       )}
                                                  </Col>
                                                  <Col xs={6}>
                                                       <Button onClick={this.handleReset} variant="danger">
                                                            Reset
                                                       </Button>
                                                  </Col>
                                             </div>
                                        )}
                                        {previous_path === "edit" && (
                                             <div className="button-group __submit row">
                                                  <Col xs={6}>
                                                       {loading ? (
                                                            <Button variant="outline-primary" disabled style={{ backgroundColor: "#fff" }}>
                                                                 <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                                 Loading...
                                                            </Button>
                                                       ) : (
                                                            <Button onClick={this.handleEdit} disabled={loading}>
                                                                 บันทึกการแก้ไข
                                                            </Button>
                                                       )}
                                                  </Col>
                                                  <Col xs={6}>
                                                       <Button onClick={this.handleDelete} variant="danger">
                                                            ลบข้อมูล
                                                       </Button>
                                                  </Col>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </Form>
                    )}
               </div>
          );
     }
}
