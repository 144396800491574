import React, { Component } from "react";
import { Breadcrumbs, Table, Search, Img, Loader } from "components/index";
import { GET } from "api/index";
import dayjs from "dayjs";
import { getToken } from "static/function";

export default class ListCar extends Component {
     constructor(props) {
          super(props);

          this.state = {
               carmanage: [],
               search: this.props.location.state,
               newSearch: [],
               user: {},
               loading: false,
          };
     }

     componentDidMount = async () => {
          await this.setState({ user: getToken() });
          this.getCar();
     };

     getCar = async () => {
          this.setState({ loading: true });
          try {
               let { item } = this.props.location.state;
               if (item === "pending") {
                    let result = await GET("/car/get_car_first");
                    this.setState({ carmanage: result, loading: false });
               } else {
                    let result = await GET("/car/get");
                    // console.log("item", item);
                    let data = [];
                    if (item === "sale") {
                         data = result.filter((el) => el.acept_status !== "ส่งมอบรถแล้ว" && el.acept_status !== "ปิดการขาย");
                    } else {
                         data = result.filter((el) => el.acept_status === "ส่งมอบรถแล้ว" || el.acept_status === "ปิดการขาย");
                         // .map((el) => ({
                         //      ...el,
                         //      year: el.order_code_id ? Number(el.order_code_id.slice(3, 5)) : null,
                         //      month: el.order_code_id ? Number(el.order_code_id.slice(5, 7)) : null,
                         // }));
                    }
                    // console.log(data.length);
                    this.setState({ carmanage: data });
                    this.handleClickSearch(data);
               }
          } catch (error) {
               this.setState({ loading: false });
               console.log(error);
          }
     };

     dateFormatter = (cell, row, rowIndex, formatExtraData) => {
          return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
     };

     handleClick = () => {
          window.location.assign("/admin/car/add");
     };

     handleClickSearch = (carmanage) => {
          let { item } = this.props.location.state;
          let { search } = this.state;
          // console.log(search);

          // if (search.car_type === "ทั้งหมด") {
          //      search.car_type = "";
          //      this.setState({ search });
          // }
          if (item === "sale") {
               let newSearch = carmanage
                    .filter(
                         (el) =>
                              // (search.car_engine ? String(el.car_engine) === String(search.car_engine) : el) &&
                              // (search.car_tank ? String(el.car_tank) === String(search.car_tank) : el) &&
                              (search.car_date ? dayjs(el.car_create).format("DD/MM/YYYY") === search.car_date : el) &&
                              // (search.car_type ? String(el.car_type) === String(search.car_type) : el) &&
                              (search.car_series ? String(el.series_name) === String(search.car_series) : el) &&
                              (search.car_model ? String(search.car_model).includes(String(el.model_name)) : el) &&
                              (search.car_color ? String(el.color_name) === String(search.car_color) : el)
                    )
                    .filter(
                         (el) =>
                              (search.car_engine ? String(el.car_engine).indexOf(String(search.car_engine)) > -1 : el) &&
                              (search.car_tank ? String(el.car_tank).indexOf(String(search.car_tank)) > -1 : el)
                    );
               // console.log("newSearch", newSearch);
               this.setState({ carmanage: newSearch, loading: false });
               // console.log(search?.cat_date)
               // console.log(carmanage?.car_create)
               // carmanage.map(e => console.log('e.car_create', dayjs(e.car_create).format('DD/MM/YYYY')))
          } else {
               // console.log("search", search);
               let newSearch = carmanage.filter(
                    (el) =>
                         (search.car_engine_success ? String(el.car_engine).indexOf(String(search.car_engine_success)) > -1 : el) &&
                         (search.car_tank_success ? String(el.car_tank).indexOf(String(search.car_tank_success)) > -1 : el) &&
                         (search.order_code_id ? String(el.order_code_id).indexOf(String(search.order_code_id)) > -1 : el)
                    // (search.year ? el.year === Number(search.year) : el) &&
                    // (search.month && search.month !== "0" ? el.month === Number(search.month) : el)
               );
               this.setState({ carmanage: newSearch, loading: false });
          }
     };

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { user } = this.state;
          return user?.position_id === 18 ? (
               <i
                    className="far fa-file-alt"
                    style={{ cursor: "", color: row.order_id > 0 ? "red" : row.status === "ไม่ได้ใช้งาน" ? "red" : "black" }}
               />
          ) : (
               <i
                    className="far fa-file-alt"
                    style={{ cursor: "pointer", color: row.order_id > 0 ? "red" : row.status === "ไม่ได้ใช้งาน" ? "red" : "black" }}
                    onClick={() => window.location.assign(`/admin/car/edit/${row.car_id}`)}
               />
          );
     };

     render() {
          let { newSearch, user } = this.state;
          let { item } = this.props.location.state;
          //  console.log("newSearch",newSearch)
          const column = [
               {
                    dataField: "edit",
                    text: "ข้อมูลรถ",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __left __icon",
               },
               {
                    dataField: "series_name",
                    text: "Series",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "model_name",
                    text: "Model",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "model_code",
                    text: "Model Code",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "color_name",
                    text: "Color",
                    headerClasses: "header-custom __btn-ss",
               },
               {
                    dataField: "car_tank",
                    text: "เลขตัวถัง",
                    headerClasses: "header-custom __btn-ml",
               },
               // {
               //      dataField: "car_engine",
               //      text: "เลขเครื่องยนต์",
               //      headerClasses: "header-custom __btn-s",
               // },
               {
                    dataField: "car_note",
                    text: "หมายเหตุ",
                    headerClasses: "header-custom __btn-ml",
                    formatter: (c) => (c ? c : "-"),
               },
               {
                    dataField: "week",
                    text: user?.position_id !== 25 ? "WEEK" : "WEEK (id)",
                    headerClasses: "header-custom __btn-s",
                    // formatter: (c) => (c ? c : "-"),
                    formatter: (c, e) => (c ? c + (user?.position_id === 25 ? ` (${e.car_id})` : "") : "-"),
               },
               user?.position_id === 25 && {
                    dataField: "region_code",
                    text: "Region",
                    headerClasses: "header-custom __btn-ss",
               },
               user?.position_id === 25 && {
                    dataField: "assi_date",
                    text: "Assi Date",
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "order_code_id",
                    text: "ใบจองที่จับคู่",
                    headerClasses: "header-custom __btn-s",
                    formatter: (c, e) =>
                         e.order_code_id ? (
                              <strong
                                   style={{ color: "red", textDecoration: "underline", cursor: "pointer" }}
                                   onClick={() => window.location.assign(`/admin/order/car/detail${e.order_id}`)}
                              >
                                   {e.order_code_id}
                              </strong>
                         ) : e.status === "ไม่ได้ใช้งาน" ? (
                              <strong style={{ color: "red" }}>รถล็อค</strong>
                         ) : (
                              "ว่าง"
                         ),
               },
               {
                    dataField: "userinfo_name",
                    text: "ชื่อลูกค้า",
                    headerClasses: "header-custom __btn-ml",
                    formatter: (c) => (c ? c : "-"),
               },
               {
                    dataField: "saler_name_string",
                    text: "พนักงานขาย",
                    headerClasses: "header-custom __right __btn-ml",
                    formatter: (c) => (c ? c : "-"),
               },
          ];
          const pending = [
               {
                    dataField: "edit",
                    text: "ข้อมูลรถ",
                    formatter: this.detailFormatter,
                    headerClasses: "header-custom __left __icon",
               },
               {
                    dataField: "series_name",
                    text: "Series",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "model_name",
                    text: "Model",
                    headerClasses: "header-custom __btn-ml",
               },
               {
                    dataField: "model_code",
                    text: "Model Code",
                    headerClasses: "header-custom __btn-m",
               },
               {
                    dataField: "color_name",
                    text: "Color",
                    headerClasses: "header-custom __btn-ss",
               },
               // {
               //      dataField: "car_tank",
               //      text: "เลขตัวถัง",
               //      headerClasses: "header-custom __btn-ml",
               // },
               {
                    dataField: "car_note",
                    text: "หมายเหตุ",
                    headerClasses: "header-custom __btn-ml",
                    formatter: (e) => <strong>{e ? e : "-"}</strong>,
               },
               {
                    dataField: "week",
                    text: user?.position_id !== 25 ? "WEEK" : "WEEK (id)",
                    headerClasses: "header-custom __btn-s",
                    formatter: (c, e) => (c ? c + (user?.position_id === 25 ? ` (${e.car_id})` : "") : "-"),
               },
               user?.position_id === 25 && {
                    dataField: "region_code",
                    text: "Region",
                    headerClasses: "header-custom __btn-ss",
               },
               user?.position_id === 25 && {
                    dataField: "assi_date",
                    text: "Assi Date",
                    headerClasses: "header-custom __btn-s",
               },
               {
                    dataField: "order_code_id",
                    text: "ใบจองที่จับคู่",
                    headerClasses: "header-custom __btn-s",
                    formatter: (c, e) =>
                         e.order_code_id ? (
                              <strong
                                   style={{ color: "red", textDecoration: "underline", cursor: "pointer" }}
                                   onClick={() => window.location.assign(`/admin/order/car/detail${e.order_id}`)}
                              >
                                   {e.order_code_id}
                              </strong>
                         ) : e.status === "ไม่ได้ใช้งาน" ? (
                              <strong style={{ color: "red" }}>รถล็อค</strong>
                         ) : (
                              "ว่าง"
                         ),
               },
               {
                    dataField: "userinfo_name",
                    text: "ชื่อลูกค้า",
                    headerClasses: "header-custom __btn-ml",
                    formatter: (c) => (c ? c : "-"),
               },
               {
                    dataField: "saler_name_string",
                    text: "พนักงานขาย",
                    headerClasses: "header-custom __right __btn-ml",
                    formatter: (c) => (c ? c : "-"),
               },
          ];
          return (
               // <div className="panel hide-scroll">
               <div className="panel">
                    <Breadcrumbs
                         title={[{ title: "รายการรถยนต์" }]}
                         active={0}
                         button={[
                              {
                                   icon: "fas fa-user-plus -icon",
                                   name: "เพิ่มรถ",
                                   variant: "primary",
                                   handleClick: this.handleClick,
                              },
                         ]}
                    />

                    <div className="content __main-panel listcar">
                         {this.state.loading ? (
                              <Loader />
                         ) : (
                              <Table
                                   type={"summary"}
                                   column={item === "pending" ? pending : column}
                                   data={this.state.carmanage.map((el) => ({
                                        ...el,
                                        // car_type: el.car_type ? el.car_type : "-",
                                        order_id: el.order_id > 0 ? el.order_id : "-",
                                   }))}
                                   keyField={"no"}
                                   amount={this.state.carmanage.length}
                                   leasing="list-car"
                              />
                         )}
                    </div>
               </div>
          );
     }
}
