import { Breadcrumbs, LabelBar, Table, Field, ModalPanel } from "components/index";
import React, { Component } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { POST, ip } from "api";
import InputPanel from "components/input-panel/input-panel";
import swal from "sweetalert";
import dayjs from "dayjs";
import { getToken_permission_page } from "../../../../../static/function";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

function dateFormatter(cell) {
     return dayjs(cell).format("DD/MM/") + (dayjs(cell).year() + 543);
}
export default class GenerateInstallList extends Component {
     constructor(props) {
          super(props);
          this.state = {
               order_id: this.props.match.params.id,
               order: {
                    ac: [],
               },
               check: [],
               updateAcc: [],
               price: {
                    fun_total: 0,
                    sel_total: 0,
               },
               btn_approve: false,
               arrChecked: [],
               showmodal_email_sup: false,
               // mail_acc_install_date: dayjs(new Date()).format("YYYY-MM-DD"),
               mail_acc_install_date: dayjs(new Date()),
               vender_date: dayjs(new Date()),
               cancel: {
                    cancel_note: "",
               },
               modal_approve: false,
               modal_vendor: false,
               vender_item: [],
               vender_order: {},
               showModalInstall: false,
               showModalCancel: false,
               editNote: false,
               showModalApprove: false,
               showModalApproveNote: false,
               loading_btn: false,
               acced: [],
          };
     }

     componentDidMount = () => {
          this.getOrder();
     };

     getOrder = async () => {
          let { order_id, data_table, updateAcc, price, arrChecked } = this.state;
          price.fun_total = 0;
          price.sel_total = 0;
          try {
               let order = await POST("/order/this_order_instal_ac", { order_id: order_id });
               for (let i in order?.ac) {
                    order.ac[i].install === "ติดตั้งแล้ว" ? arrChecked.push(true) : arrChecked.push(false);
                    price.fun_total += JSON.parse(order?.ac[i].price_list).cost * order?.ac[i].order_ac_amount;
                    if (order?.ac[i].order_ac_type === "bonus") {
                         // price.sel_total += JSON.parse(order?.ac[i].price_list).price_add_new * order?.ac[i].order_ac_amount;
                         price.sel_total += order?.ac[i].order_ac_price * order?.ac[i].order_ac_amount;
                    } else {
                         price.sel_total += order?.ac[i].order_ac_price * order?.ac[i].order_ac_amount;
                    }
                    if (order?.ac[i].order_ac_type === "bonus") {
                         order.ac[i]["order_ac_type"] = "แถม";
                    } else if (order?.ac[i].order_ac_type === "buy") {
                         order.ac[i]["order_ac_type"] = "ลูกค้าซื้อเอง";
                    }

                    updateAcc[i] = {
                         orderac_id: order.ac[i].orderac_id,
                         install: order.ac[i].install,
                         isInstalled: order.ac[i].isInstalled,
                         send_mail: order.ac[i].send_mail,
                         items_id: order.ac[i].items_id,
                         items_name: order.ac[i].items_name,
                         supplier_name: order.ac[i].supplier_name,
                    };
               }

               // order.ac_install = 'รอการติดตั้ง'
               // console.log(`car_ac`, order?.car_acc);

               // let acInstalled = null;
               // if (order?.ac) {
               //      for (let i in order?.car_acc) {
               //           console.log(`object`, order.car_acc[i]);
               //           acInstalled = order?.ac.find((el) => el.items_id === order.car_acc[i].items_id);
               //           if (acInstalled) {
               //                order.ac[i].install = "ติดตั้งแล้ว";
               //                arrChecked[i] = true;
               //           }
               //      }
               // }
               let install_status = order?.ac.find((el) => el.install === "รอการติดตั้ง");
               this.setState({ btn_approve: install_status ? false : true });
               let footer = [
                    {
                         supplier_name: null,
                         items_name: "",
                         order_ac_type: "",
                         isInstalled: "",
                         order_ac_amount: "รวมราคาสินค้า",
                         items_fun: price.fun_total,
                         items_price: price.sel_total,
                         cancel: "XXX",
                         field: "summary-detail",
                    },
                    {
                         supplier_name: null,
                         items_name: "",
                         order_ac_type: "",
                         isInstalled: "",
                         order_ac_amount: "ภาษีมูลค่าเพิ่ม 7%",
                         items_fun: "-",
                         items_price: (price.sel_total * 7) / 107,
                         cancel: "XXX",
                         field: "summary-detail",
                    },
               ];
               // order.ac.push(footer[0], footer[1]);
               order.ac.push(footer[0]);
               let acc =
                    order.acced && order.acced.length > 2 ? JSON.parse(order.acced).map((e, i) => ({ ...e, index: i + 1, items_type: "-" })) : [];
               this.setState({ order: { ...order }, updateAcc, price, arrChecked, acced: acc });
          } catch (err) {
               console.log(err);
          }
     };

     getVenderToUpdate = async (row) => {
          // console.log(`row`, row);
          let { vender_order } = this.state;
          vender_order.orderac_id = row.orderac_id;
          try {
               let item = await POST("/item/to_change_vendor", {
                    items_name: row.items_name,
                    items_price: row.items_price,
                    items_fun: row.items_fun,
                    items_id: row.items_id,
               });

               // console.log(`item`, item);
               if (item.length === 0) {
                    swal({
                         icon: "warning",
                         title: "ไม่มี Supplier",
                         buttons: { Catch: { text: "ตกลง", value: "success" } },
                         closeOnClickOutside: true,
                    });
               } else {
                    this.setState({ modal_vendor: true, vender_item: item });
               }
          } catch (error) {
               console.log(`error`, error);
          }
     };

     onClickSetup = async () => {
          let { order, check, updateAcc, order_id } = this.state;
          // if (!order?.order_mail_acc) {
          //      this.setState({ showmodal_email_sup: true });
          // } else {
          let arr = order.ac;
          order.ac = [];
          await this.setState({ order });
          order.ac = arr;
          for (let i in check) {
               updateAcc[i].orderac_id = order.ac[i].orderac_id;
               if (check[i]) {
                    updateAcc[i].install = "ติดตั้งแล้ว";
                    order.ac[i].install = "ติดตั้งแล้ว";
                    updateAcc[i].isInstalled = order.ac[i].order_ac_amount;
                    updateAcc[i].send_mail = order.ac[i].send_mail;
               } else {
                    updateAcc[i].install = "รอการติดตั้ง";
                    order.ac[i].install = "รอการติดตั้ง";
                    updateAcc[i].isInstalled = 0;
                    updateAcc[i].send_mail = order.ac[i].send_mail;
               }
          }
          try {
               this.setState({ showModalInstall: !this.state.showModalInstall });
               // console.log("updateAcc", updateAcc);
               let result = await POST("/order/update_order_ac", { updateAcc: updateAcc.filter((el) => el.send_mail), order_id: order.order_id });
               if (result === "update success") {
                    swal({
                         icon: "success",
                         title: "บันทึกเรียบร้อยแล้ว",
                         text: " ",
                         // buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                         closeOnClickOutside: false,
                         timer: 1500,
                         buttons: false,
                    }).then(() => {
                         // this.getOrder();
                         window.location.reload();
                    });
               }

               // let install_status = order?.ac.find((el) => el.install === "รอการติดตั้ง");
               // this.setState({ btn_approve: install_status ? false : true, check: [] });
          } catch (err) {
               console.log(err);
          }
          this.setState({
               order,
               updateAcc,
          });
     };

     onClickApprove = async () => {
          this.setState({ loading_btn: true, showModalApprove: false });
          let { order } = this.state;
          try {
               let check_install = order.ac.some((el) => el.isInstalled === 0);
               // this.setState({ modal_approve: true });
               let result = await POST("/order/acept_order_ac", {
                    order_id: order.order_id,
                    ac_install_note: order.ac_install_note,
                    check_install,
               });

               // this.setState({ showModalApproveNote: false, showModalApprove: false });
               if (result === "update success") {
                    swal({
                         icon: "success",
                         title: "อนุมัติการติดตั้งอุปกรณ์เรียบร้อย",
                         buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                         closeOnClickOutside: false,
                    }).then((value) => {
                         window.location.assign(`/admin/install/list`);
                    });
               }
          } catch (err) {
               this.setState({ loading_btn: false });
               console.log(err);
               swal({
                    icon: "warning",
                    title: err,
                    buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                    closeOnClickOutside: false,
               }).then(() => window.location.reload());
          }
     };
     onClickSaveNote = async () => {
          this.setState({ loading_btn: true, showModalApproveNote: false });
          let { order } = this.state;
          try {
               // this.setState({ modal_approve: true });
               let result = await POST("/order/order_ac_save_note", {
                    order_id: order.order_id,
                    ac_install_note: order.ac_install_note,
               });

               if (result === "update success") {
                    swal({
                         icon: "success",
                         title: "บันทึกเรียบร้อยแล้ว",
                         buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                         closeOnClickOutside: false,
                    }).then((value) => {
                         // window.location.assign(`/admin/install/list/car/${order.order_id}`);
                         window.location.reload();
                    });
               }
          } catch (err) {
               this.setState({ loading_btn: false });
               console.log(err);
          }
     };

     handleChangeTextCancel = (e) => {
          let { cancel } = this.state;
          cancel[e.target.name] = e.target.value;
          this.setState({ cancel });
     };

     handleChangeSelect = (e) => {
          let { cancel } = this.state;
          cancel[e.target.name] = e.target.value;
          this.setState({ cancel });
     };

     handleChangeVender = (e) => {
          let { vender_order } = this.state;
          vender_order[e.target.name] = e.target.value;
          this.setState({ vender_order });
     };

     onCancel = async () => {
          this.setState({ loading_btn: true, showModalCancel: false });
          let { cancel, order } = this.state;
          try {
               await POST("/car/cancel_accept_match", {
                    order_id: order.order_id,
                    car_id: order.car_id,
                    reject_install_note: cancel.cancel_note,
                    install: true,
               });
               swal({
                    icon: "success",
                    title: "ยกเลิกติดตั้งอุปกรณ์เรียบร้อยแล้ว",
                    buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                    closeOnClickOutside: false,
               }).then((value) => {
                    // window.location.assign(`/admin/install/list/car/${order.order_id}`);
                    window.location.assign(`/admin/install/list`);
               });
               // if (cancel.cancel_select_type === "ยกเลิกการติดตั้งอุปกรณ์") {
               //      // reject_install_note
               //      await POST("/car/cancel_accept_match", {
               //           order_id: this.state.order_id,
               //           car_id: order.car_id,
               //           reject_install_note: cancel.cancel_note,
               //           install: true,
               //      });
               //      swal({
               //           icon: "success",
               //           text: "ยกเลิกการติดตั้งอุปกรณ์แล้ว",
               //           buttons: {
               //                submit: "เสร็จสิ้น",
               //           },
               //      }).then(() => {
               //           window.location.assign("/admin/install/list");
               //      });
               // } else {
               //      swal({
               //           icon: "warning",
               //           text: "กรุณาเลือกรายการที่ต้องการยกเลิก",
               //           buttons: {
               //                danger: "ตกลง",
               //           },
               //      });
               // }
               // if (cancel.cancel_select_type === "ยกเลิกการยืนยันการจับคู่รถ") {
               //      // reject_match_note
               //      this.setState({ showModalCancel: !this.state.showModalCancel });
               //      await POST("/car/cancel_accept_match", {
               //           order_id: this.state.order_id,
               //           car_id: order.car_id,
               //           reject_match_note: cancel.cancel_note,
               //      });
               //      swal({
               //           icon: "success",
               //           text: "ยกเลิกการยืนยันการจับคู่รถแล้ว",
               //           buttons: {
               //                submit: "เสร็จสิ้น",
               //           },
               //      }).then(() => {
               //           window.location.assign("/admin/install/list");
               //      });
               // } else if (cancel.cancel_select_type === "ยกเลิกการแจ้งติดตั้งอุปกรณ์") {
               //      // reject_order_acc_note
               //      this.setState({ showModalCancel: !this.state.showModalCancel });
               //      await POST("/car/cancel_odrder_acc", {
               //           order_id: this.state.order_id,
               //           car_id: order.car_id,
               //           reject_order_acc_note: cancel.cancel_note,
               //      });
               //      swal({
               //           icon: "success",
               //           text: "ยกเลิกการแจ้งติดตั้งอุปกรณ์แล้ว",
               //           buttons: {
               //                submit: "เสร็จสิ้น",
               //           },
               //      }).then(() => {
               //           window.location.assign("/admin/install/list");
               //      });
               // } else if (cancel.cancel_select_type === "ยกเลิกการติดตั้งอุปกรณ์") {
               //      // reject_install_note
               //      await POST("/car/cancel_accept_match", {
               //           order_id: this.state.order_id,
               //           car_id: order.car_id,
               //           reject_install_note: cancel.cancel_note,
               //      });
               //      swal({
               //           icon: "success",
               //           text: "ยกเลิกการติดตั้งอุปกรณ์แล้ว",
               //           buttons: {
               //                submit: "เสร็จสิ้น",
               //           },
               //      }).then(() => {
               //           window.location.assign("/admin/install/list");
               //      });
               // } else {
               //      swal({
               //           icon: "warning",
               //           text: "กรุณาเลือกรายการที่ต้องการยกเลิก",
               //           buttons: {
               //                danger: "ตกลง",
               //           },
               //      });
               // }
          } catch (error) {
               this.setState({ loading_btn: false });
               console.log(`error`, error);
          }
     };

     cancelAcc = async (row) => {
          try {
               swal({
                    icon: "warning",
                    text: "ยืนยันการยกเลิก",
                    buttons: {
                         submit: "ยืนยัน",
                         cancel: "ไม่ยืนยัน",
                    },
               }).then(async (value) => {
                    if (value === "submit") {
                         await POST("/car/update_acc", {
                              orderac_id: row.orderac_id,
                              delete: 1,
                         });

                         swal({
                              icon: "success",
                              text: "ยกเลิกอุปกรณ์แล้ว",
                              buttons: {
                                   submit: "เสร็จสิ้น",
                              },
                         }).then(() => {
                              this.getOrder();
                         });
                    } else return null;
               });
          } catch (error) {
               console.log(`error`, error);
          }
     };

     changeVender = async () => {
          let { vender_order } = this.state;
          if (vender_order.items_id) {
               try {
                    vender_order.send_mail_change_date = dayjs(new Date()).format("YYYY-MM-DD");
                    vender_order.mail_change_install_date = dayjs(this.state.vender_date).format("YYYY-MM-DD");
                    await POST("/item/update_vendor", vender_order);
                    this.setState({ modal_vendor: false });
                    swal({
                         icon: "success",
                         text: "เลือก Supplier แล้ว",
                         buttons: {
                              submit: "เสร็จสิ้น",
                         },
                         closeOnClickOutside: false,
                    }).then(() => {
                         this.getOrder();
                         delete vender_order.items_id;
                         this.setState({ modal_vendor: false, vender_order });
                    });
               } catch (error) {
                    console.log(`error`, error);
               }
          } else {
               swal({
                    icon: "warning",
                    text: "กรุณาเลือก Supplier",
                    buttons: {
                         submit: "ตกลง",
                    },
               });
          }
     };

     handleSelectDateMail = (date, dateString, name) => {
          this.setState({ mail_acc_install_date: date });
     };

     handleChangeVenderDate = (date, dateString, name) => {
          this.setState({ vender_date: date });
     };

     handleChangeNote = (e) => {
          let { order } = this.state;
          order.ac_install_note = e.target.value;
          this.setState({ order });
     };

     onChangeCheckboxAdd = (checked, row, rowIndex) => {
          let { check, arrChecked } = this.state;
          if (row && checked.orderac_id && checked.isInstalled === 0) {
               check[rowIndex] = checked;
               arrChecked[rowIndex] = !arrChecked[rowIndex];
          } else {
               let find = check.find((el) => el?.orderac_id === checked?.orderac_id);
               if (find) {
                    check.splice(
                         check.findIndex((el) => el?.orderac_id === find?.orderac_id),
                         1
                    );
               }
               arrChecked[rowIndex] = !arrChecked[rowIndex];
          }
          this.setState({ check, arrChecked });
     };

     /* --------------------formatter -------------------- */

     telFormat = (tel) => {
          let tel_add = tel;
          for (let i = 0; i < 10; i++) {
               if (tel_add.length !== 10) tel_add = tel_add.concat("X");
          }
          let arr = tel_add.split("");
          let tel_format = arr[0] + arr[1] + arr[2] + "-" + arr[3] + arr[4] + arr[5] + "-" + arr[6] + arr[7] + arr[8] + arr[9];
          return tel_format;
     };

     checkBoxFormatter = (cell, row, rowIndex, formatExtraData) => {
          let { check } = this.state;
          if (row.supplier_name === null) {
               return null;
          } else {
               return (
                    <LabelBar
                         // handleChangeText={(e) => this.onChangeCheckboxAdd(e.target.checked, row, rowIndex)}
                         type={"checkbox-check"}
                         name={row.install}
                         label={[{ value: rowIndex }]}
                         checked={formatExtraData[rowIndex].value}
                         disabled={row.isInstalled > 0}
                         // disabled={formatExtraData[rowIndex].value}
                    />
               );
          }
     };

     changeVenderFormatter = (cell, row, rowIndex, formatExtraData) => {
          // console.log(`row`, row);
          if (cell) {
               return (
                    <div>
                         {cell}
                         {/* <br></br>
                         <a onClick={() => this.getVenderToUpdate(row)} style={{ color: "#F11BBA" }}>
                              เปลี่ยน
                         </a> */}
                    </div>
               );
          } else {
               return "";
          }
     };

     parseFloatFormatter(cell, row) {
          if (row.order_ac_amount === "รวมราคาสินค้า" || row.order_ac_amount === "ภาษีมูลค่าเพิ่ม 7%") {
               let new_cell = Number(cell).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
               });
               if (row.field === "summary-total") {
                    return <span className="text">{new_cell}</span>;
               } else {
                    return new_cell;
               }
          } else {
               let new_cell;
               if (row.order_ac_type === "แถม") {
                    new_cell = Number(row.order_ac_price * row.order_ac_amount).toLocaleString(undefined, {
                         minimumFractionDigits: 2,
                         maximumFractionDigits: 2,
                    });
               } else {
                    new_cell = Number(row.order_ac_price * row.order_ac_amount).toLocaleString(undefined, {
                         minimumFractionDigits: 2,
                         maximumFractionDigits: 2,
                    });
               }

               if (row.field === "summary-total") {
                    return <span className="text">{new_cell}</span>;
               } else {
                    return new_cell;
               }
          }
     }
     parseFloatFormatter2(cell, row) {
          if (row.order_ac_amount === "รวมราคาสินค้า" || row.order_ac_amount === "ภาษีมูลค่าเพิ่ม 7%") {
               let new_cell = Number(cell).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
               });
               if (row.items_fun === "-") {
                    return <div></div>;
               } else {
                    if (row.field === "summary-total") {
                         return <span className="text">{new_cell}</span>;
                    } else {
                         return new_cell;
                    }
               }
          } else {
               let new_cell = Number(cell * row.order_ac_amount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
               });
               if (row.items_fun === "-") {
                    return <div></div>;
               } else {
                    if (row.field === "summary-total") {
                         return <span className="text">{new_cell}</span>;
                    } else {
                         return new_cell;
                    }
               }
          }
     }

     detailFormatter = (cell, row, rowIndex, formatExtraData) => {
          if (row.install === "ติดตั้งแล้ว") {
               return (
                    <span style={{ color: "green" }}>
                         <b>{cell}</b>
                    </span>
               );
          } else {
               return <span>{cell}</span>;
          }
     };

     cancelFormatter = (cell, row, rowIndex, formatExtraData) => {
          // console.log(`row`, row);
          if (cell) {
               return "";
          } else {
               if (formatExtraData[rowIndex].value) {
                    return (
                         <div>
                              <Button disabled={true} variant="primary">
                                   ยกเลิก
                              </Button>
                         </div>
                    );
               } else {
                    return (
                         <div>
                              <Button variant="danger" onClick={() => this.cancelAcc(row)}>
                                   ยกเลิก
                              </Button>
                         </div>
                    );
               }
          }
     };

     sendMailAccFormatter = (cell, row) => {
          if (cell === 0) {
               return <span>-</span>;
          } else if (cell === 1) {
               return <span style={{ color: "green" }}>สั่งซื้อแล้ว</span>;
          } else {
               return null;
          }
     };
     installAccFormatter = (cell, row) => {
          if (cell === 0) {
               return <span>-</span>;
          } else if (cell === 1) {
               return <span style={{ color: "green" }}>ติดตั้งแล้ว</span>;
          } else {
               return null;
          }
     };

     // ---------------- Modal Body ---------------- //

     modalEmailSupplier = () => {
          return (
               <div style={{ margin: "0 20%" }}>
                    <h4 style={{ textAlign: "center", marginBottom: "10%" }}>สั่งซื้ออุปกรณ์</h4>
                    <Field
                         type={"date"}
                         title={"วันที่สั่งซื้ออุปกรณ์"}
                         placeholder={this.state.mail_acc_install_date ? this.state.mail_acc_install_date : "เลือกวันที่"}
                         handleSelectDate={this.handleSelectDateMail}
                         formatDate={"DD/MM/YYYY"}
                         value={this.state.mail_acc_install_date}
                    />
               </div>
          );
     };
     modalApprove = () => {
          return (
               <div style={{ padding: "0 5%" }}>
                    <div style={{ fontSize: "20px", textAlign: "center" }}>
                         <span>ยืนยันการติดตั้งอุปกรณ์</span>
                    </div>
                    <LabelBar type={"radio"} title={"สถานะ"} label={["ใช้งาน", "ไม่ได้ใช้งาน"]} />
                    <InputPanel
                         title={"หมายเหตุ"}
                         type={"textarea"}
                         // name={"cancel_note"}
                         // placeholder={cancel?.cancel_note}
                         // value={cancel?.cancel_note}
                         // handleChange={(e) => this.handleChangeTextCancel(e)}
                         rows={3}
                    />
               </div>
          );
     };

     modalVendor = () => {
          // console.log(`this.state.vender_item`, this.state.vender_item);
          return (
               <div>
                    <Field
                         name={"items_id"}
                         type={"select"}
                         title={"Supplier"}
                         value={this.state.vender_order.items_id}
                         option={this.state.vender_item?.map((el) => ({ value: el.items_id, name: el.supplier_name }))}
                         handleChangeText={(e) => this.handleChangeVender(e)}
                    />
                    <Field
                         formatDate={"DD/MM/YYYY"}
                         type={"date"}
                         title={"วันที่ติดตั้งอุปกรณ์"}
                         placeholder={this.state.vender_date ? this.state.vender_date : "เลือกวันที่"}
                         value={this.state.vender_date}
                         handleSelectDate={this.handleChangeVenderDate}
                         name={"vender_date"}
                    />
               </div>
          );
     };

     sendEmail = async () => {
          try {
               let r = await POST("/order/send_mail_acc", {
                    order_id: this.state.order_id,
                    mail_acc_install_date: dayjs(this.state.mail_acc_install_date).format("YYYY-MM-DD"),
                    type_mail: "order",
                    send_mail_acc_date: dayjs(new Date()).format("YYYY-MM-DD"),
               });
               this.setState({ showmodal_email_sup: false });
               swal({
                    icon: "success",
                    title: "สั่งซื้ออุปกรณ์เรียบร้อยแล้ว",
                    text: "ข้อมูลการสั่งซื้อจะถูกส่งไปยัง Email ของ Supplier",
                    buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                    closeOnClickOutside: false,
               }).then(() => {
                    window.location.reload();
               });
          } catch (err) {
               console.log(err);
          }
     };
     onClickInstall = async () => {
          this.setState({ loading_btn: true, showModalInstall: false });
          try {
               let { supplier_name, data_item } = this.state;
               // console.log("first", data_acc, supplier_name);
               await POST("/order/update_order_ac", {
                    order_id: this.state.order_id,
                    supplier_name,
                    data_item,
               });
               swal({
                    icon: "success",
                    title: "ติดตั้งเรียบร้อยแล้ว",
                    text: supplier_name + "\nถูกติดตั้งอุปกรณ์ทั้งหมดแล้ว",
                    buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                    closeOnClickOutside: false,
               }).then(() => {
                    window.location.reload();
               });
          } catch (error) {
               this.setState({ loading_btn: false });
               swal({ icon: "error", title: "ผิดพลาด", text: "?" });
          }
     };
     onClickSendMail = async () => {
          this.setState({ loading_btn: true, showmodal_email_sup: false });
          try {
               let { data_acc, supplier_name, data_item, supplier_id, supplier_mail } = this.state;
               // console.log("first", data_acc, supplier_name);
               await POST("/order/send_mail_acc", {
                    order_id: this.state.order_id,
                    mail_acc_install_date: dayjs(new Date()).format("YYYY-MM-DD"),
                    type_mail: "order",
                    send_mail_acc_date: dayjs(new Date()).format("YYYY-MM-DD"),
                    data_acc,
                    supplier_name,
                    supplier_id,
                    data_item,
                    supplier_mail,
               });
               swal({
                    icon: "success",
                    title: "สั่งซื้ออุปกรณ์เรียบร้อยแล้ว",
                    text: "ข้อมูลการสั่งซื้อจะถูกส่งไปยัง Email ของ Supplier",
                    buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                    closeOnClickOutside: false,
               }).then(() => {
                    window.location.reload();
               });
          } catch (error) {
               this.setState({ loading_btn: false });
               swal({ icon: "error", title: "ผิดพลาด", text: "?" });
          }
     };
     onCancelSO = async () => {
          this.setState({ loading_btn: true, showCancelSO: false });
          try {
               let { data_acc, supplier_id } = this.state;
               // console.log("first", data_acc, supplier_name);
               await POST("/order/cancel_so", {
                    order_id: this.state.order_id,
                    data_acc,
                    supplier_id,
               });
               swal({
                    icon: "success",
                    title: "ยกเลิกซื้ออุปกรณ์เรียบร้อยแล้ว",
                    text: "",
                    buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                    closeOnClickOutside: false,
               }).then(() => {
                    window.location.reload();
               });
          } catch (error) {
               this.setState({ loading_btn: false });
               swal({ icon: "error", title: "ผิดพลาด", text: "?" });
          }
     };
     downloadPdf = async () => {
          this.setState({ loading_btn: true });
          try {
               let token = localStorage.getItem("token");
               let { order_id, supplier_id } = this.state;
               await axios
                    .post(
                         `${ip}/order/act_pdf`,
                         { order_id, supplier_id },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         window.open(fileURL);
                         this.setState({ loading_btn: false });
                    });
          } catch (error) {
               this.setState({ loading_btn: false });
               console.log("error", error);
          }
     };
     downloadPdf2 = async () => {
          try {
               let token = localStorage.getItem("token");
               let { order_id } = this.state;
               await axios
                    .post(
                         `${ip}/order/acc_pdf`,
                         { order_id, type: "ต้นฉบับ" },
                         {
                              responseType: "blob",
                              headers: {
                                   Accept: "application/json",
                                   "Content-Type": "application/json",
                                   token,
                              },
                         }
                    )
                    .then(async (res) => {
                         const pdfBlob = new Blob([res.data], {
                              type: "application/pdf",
                         });
                         const fileURL = URL.createObjectURL(pdfBlob);
                         window.open(fileURL);
                         this.setState({ loading_btn: false });
                    });
          } catch (error) {
               console.log("error", error);
          }
     };
     sendEmail2 = async () => {
          this.setState({ loading_btn: true });
          try {
               let { supplier_id, supplier_mail } = this.state;
               await POST("/order/send_mail_acc_2", {
                    order_id: this.state.order_id,
                    supplier_mail,
                    supplier_id,
               });
               swal({
                    icon: "success",
                    title: "สั่งซื้ออุปกรณ์เรียบร้อยแล้ว",
                    text: "ข้อมูลการสั่งซื้อจะถูกส่งไปยัง Email ของ Supplier",
                    buttons: { Catch: { text: "เสร็จสิ้น", value: "success" } },
                    closeOnClickOutside: false,
               }).then(() => {
                    window.location.reload();
               });
          } catch (error) {
               this.setState({ loading_btn: false });
               swal({ icon: "error", title: "ผิดพลาด", text: "?" });
          }
     };
     render() {
          let { order_id, order, status, price, checked, cancel, acced } = this.state;
          const selectRow = {
               mode: "checkbox",
               clickToSelect: true,
               onSelect: this.onChangeCheckboxAdd,
               hideSelectColumn: true,
          };
          let table_new = {
               column: [
                    {
                         dataField: "index",
                         text: "ลำดับที่",
                         headerClasses: "header-custom __btn-s",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการสินค้า",
                         headerClasses: "header-custom __btn-ml",
                    },
                    {
                         dataField: "type",
                         text: "ประเภท",
                         formatter: (e) => (e === "bonus" ? "แถม" : "ซื้อ"),
                         headerClasses: "header-custom  __btn-s",
                    },
                    {
                         dataField: "send_mail",
                         text: "สั่งซื้อ",
                         headerClasses: "header-custom  __btn-s",
                         formatter: this.sendMailAccFormatter,
                    },
                    {
                         dataField: "isInstalled",
                         text: "ติดตั้ง",
                         headerClasses: "header-custom  __btn-s",
                         formatter: this.installAccFormatter,
                    },
                    {
                         dataField: "amount",
                         text: "จำนวน",
                         headerClasses: "header-custom  __btn-s",
                    },
                    {
                         dataField: "price",
                         text: "ราคาต่อหน่วย (บาท)",
                         formatter: (e) =>
                              Number(e).toLocaleString(undefined, {
                                   minimumFractionDigits: 0,
                                   maximumFractionDigits: 2,
                              }),
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "#",
                         text: "รวม (บาท)",
                         formatter: (e, row) =>
                              Number(row.amount * row.price).toLocaleString(undefined, {
                                   minimumFractionDigits: 0,
                                   maximumFractionDigits: 2,
                              }),
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data: [],
               keyField: "orderac_id",
          };

          let table = {
               column: [
                    {
                         dataField: "install",
                         text: "การติดตั้ง",
                         formatter: this.checkBoxFormatter,
                         // footer: '',
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __btn-s",
                         formatExtraData: this.state.arrChecked.map((el) => ({ value: el })),
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         // footer: '',
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __btn-m",
                         formatter: this.changeVenderFormatter,
                    },
                    {
                         dataField: "items_name",
                         text: "รายการสินค้า",
                         // footer: '',
                         headerClasses: "header-custom __btn-ml",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_type",
                         text: "ประเภท",
                         // footer: '',
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom  __btn-s",
                    },
                    {
                         dataField: "send_mail",
                         text: "สั่งติดตั้ง",
                         // footer: '',
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom  __btn-s",
                         formatter: this.sendMailAccFormatter,
                    },
                    {
                         dataField: "isInstalled",
                         text: "ติดตั้งไปแล้ว",
                         // footer: '',
                         headerClasses: "header-custom  __btn-s",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_amount",
                         text: "จำนวน",
                         // footer: 'รวมทั้งสิ้น',
                         headerClasses: "header-custom  __btn-s",
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_fun",
                         text: "ราคาทุน (บาท)",
                         footerClasses: "summary-custom",
                         formatter: this.parseFloatFormatter2,
                         // footer: '',
                         headerClasses: "header-custom  __btn-m",
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย (บาท)",
                         footerClasses: "summary-custom",
                         formatter: this.parseFloatFormatter,
                         // footer: Number(`${price?.sel_total + (price?.sel_total * 7) / 107}`).toLocaleString(undefined, {
                         //      minimumFractionDigits: 2,
                         //      maximumFractionDigits: 2,
                         // }),
                         headerClasses: "header-custom __right __btn-m",
                    },
                    // {
                    //      dataField: "cancel",
                    //      text: "ยกเลิก",
                    //      // footer: '',
                    //      footerClasses: "summary-custom",
                    //      formatter: this.cancelFormatter,
                    //      formatExtraData: this.state.arrChecked.map((el) => ({ value: el })),
                    // },
               ],
               data: order?.ac,
               keyField: "orderac_id",
          };

          let table_pending = {
               column: [
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         // footer: '',
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการสินค้า",
                         // footer: '',
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_type",
                         text: "ประเภท",
                         // footer: '',
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom  __btn-m",
                    },
                    {
                         dataField: "isInstalled",
                         text: "ติดตั้งไปแล้ว",
                         // footer: '',
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_amount",
                         text: "จำนวน",
                         // footer: 'รวมทั้งสิ้น',
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_fun",
                         text: "ราคาทุน (บาท)",
                         footerClasses: "summary-custom",
                         formatter: this.parseFloatFormatter2,
                         // footer: '',
                         headerClasses: "header-custom  __btn-m",
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย (บาท)",
                         footerClasses: "summary-custom",
                         formatter: this.parseFloatFormatter,
                         // footer: Number(`${price?.sel_total + (price?.sel_total * 7) / 107}`).toLocaleString(undefined, {
                         //      minimumFractionDigits: 2,
                         //      maximumFractionDigits: 2,
                         // }),
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data: order?.ac,
               keyField: "install",
          };

          let table2 = {
               column: [
                    {
                         dataField: "install",
                         text: "การติดตั้ง",
                         formatter: this.detailFormatter,
                         // footer: '',
                         footerClasses: "summary-custom",
                         headerClasses: "header-custom __left",
                    },
                    {
                         dataField: "supplier_name",
                         text: "Supplier Name",
                         // footer: '',
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการสินค้า",
                         // footer: '',
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_type",
                         text: "ประเภท",
                         // footer: '',
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "isInstalled",
                         text: "ติดตั้งไปแล้ว",
                         // footer: '',
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "order_ac_amount",
                         text: "จำนวน",
                         // footer: 'รวมทั้งสิ้น',
                         footerClasses: "summary-custom",
                    },
                    {
                         dataField: "items_fun",
                         text: "ราคาทุน (บาท)",
                         footerClasses: "summary-custom",
                         formatter: this.parseFloatFormatter2,
                         // footer: '',
                    },
                    {
                         dataField: "items_price",
                         text: "ราคาขาย (บาท)",
                         footerClasses: "summary-custom",
                         formatter: this.parseFloatFormatter,
                         // footer: Number(`${price?.sel_total + (price?.sel_total * 7) / 107}`).toLocaleString(undefined, {
                         //      minimumFractionDigits: 2,
                         //      maximumFractionDigits: 2,
                         // }),
                         headerClasses: "header-custom __right",
                    },
               ],
               data: order?.ac ? order?.ac : [],
               keyField: "orderac_id",
          };
          const table_acc = {
               column: [
                    {
                         dataField: "index",
                         text: "ลำดับที่",
                         headerClasses: "header-custom __left __btn-ss",
                    },
                    {
                         dataField: "items_name",
                         text: "รายการ",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "items_type",
                         text: "ประเภท",
                         headerClasses: "header-custom __btn-m",
                    },
                    {
                         dataField: "supplier_name",
                         text: "บริษัท",
                         headerClasses: "header-custom __right __btn-m",
                    },
               ],
               data2: acced.length ? acced : [],
               keyField: "index",
          };

          let token = getToken_permission_page();
          let per = token ? JSON.parse(token.permission_page) : [];
          let addBtn = per.filter((el) => el.path === "/admin/install/list")[0].add || false;
          let editBtn = per.filter((el) => el.path === "/admin/install/list")[0].edit || false;
          let editApprove = per.filter((el) => el.path === "/admin/install/list")[0].approve || false;

          switch (order?.ac_install) {
               case "รอการติดตั้ง":
               case "สั่งซื้ออุปกรณ์":
               case "ติดตั้งเรียบร้อยแล้ว":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `รายการใบจองที่พร้อมติดตั้ง`,
                                             onClick: () => window.location.assign("/admin/install/list"),
                                        },
                                        {
                                             title: `รายการใบจอง (${order?.order_code_id || ""})`,
                                             onClick: () => window.location.assign(`/admin/order/car/detail${order_id}`),
                                        },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ข้อมูลใบจอง"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขที่ใบจอง : ",
                                                                      detail: `${order?.order_code_id || ""}`,
                                                                 }}
                                                                 md={6}
                                                                 onClickLabelBar={() => window.location.assign(`/admin/order/car/detail${order_id}`)}
                                                                 textHeader_style={"underline"}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "โชว์รูม : ",
                                                                      detail: order?.branch_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "พนักงานขาย : ",
                                                                      detail: order?.saler_name_string,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ชื่อ-นามสกุล ผู้สั่งจอง : ",
                                                                      detail: order?.userinfo_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขประจำตัว : ",
                                                                      detail: order?.userinfo_idcard,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขโทรศัพท์ : ",
                                                                      detail: order?.userinfo_tel ? this.telFormat(String(order?.userinfo_tel)) : "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ : ",
                                                                      detail: `${order?.userinfo_address} ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${order?.userinfo_province} ${order?.userinfo_zipcode}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายละเอียดรถยนต์"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Series : ",
                                                                      detail: order?.series_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Model : ",
                                                                      detail: order?.model_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Color : ",
                                                                      detail: order?.color_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขตัวถัง : ",
                                                                      detail: order?.car_tank || "",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขเครื่องยนต์ : ",
                                                                      detail: order?.car_engine || "",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายการอุปกรณ์ที่ติดมากับรถยนต์"} color="red" />
                                                  <div className="-padding">
                                                       <Table
                                                            type={"summary-no-padding"}
                                                            column={table_acc.column}
                                                            data={table_acc.data2}
                                                            keyField={table_acc.keyField}
                                                       />
                                                  </div>
                                                  <div className="button-group __submit row">
                                                       <Col xs={4}>
                                                            <Button onClick={this.downloadPdf2}>พิมพ์ใบติดตั้งอุปกรณ์</Button>
                                                       </Col>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายการอุปกรณ์"} />
                                                  <div className="content" id="table-content" style={{ width: "100%", padding: " 0 12px" }}>
                                                       {order.sup.map((el, i) => (
                                                            <div key={i + 1} className="mb-5">
                                                                 <LabelBar type={"title"} title={el.supplier_name} />
                                                                 <strong>
                                                                      E-mail :{" "}
                                                                      {el.supplier_mail && el.supplier_mail !== "null" ? el.supplier_mail : "-"}
                                                                 </strong>
                                                                 <Table
                                                                      type="summary-custom-padding"
                                                                      column={table_new.column}
                                                                      data={el.data_acc}
                                                                      keyField={table_new.keyField}
                                                                 />
                                                                 {addBtn && (
                                                                      <div className="button-group __submit row">
                                                                           <Col xs={3}>
                                                                                {this.state.loading_btn ? (
                                                                                     <Button
                                                                                          variant="outline-primary"
                                                                                          disabled
                                                                                          style={{ backgroundColor: "#fff" }}
                                                                                     >
                                                                                          <Spinner
                                                                                               as="span"
                                                                                               animation="grow"
                                                                                               size="sm"
                                                                                               role="status"
                                                                                               aria-hidden="true"
                                                                                          />
                                                                                          Loading...
                                                                                     </Button>
                                                                                ) : el.data_acc.some((e) => e.send_mail === 0) ? (
                                                                                     <Button
                                                                                          onClick={() =>
                                                                                               this.setState({
                                                                                                    showmodal_email_sup: true,
                                                                                                    data_acc: el.data_acc.map((e) => e.orderac_id),
                                                                                                    data_item: el.data_acc,
                                                                                                    supplier_name: el.supplier_name,
                                                                                                    supplier_id: el.supplier_id,
                                                                                                    supplier_mail: el.supplier_mail,
                                                                                               })
                                                                                          }
                                                                                     >
                                                                                          สั่งซื้ออุปกรณ์
                                                                                     </Button>
                                                                                ) : (
                                                                                     <Button
                                                                                          style={{ background: "red", border: "none" }}
                                                                                          onClick={() =>
                                                                                               this.setState({
                                                                                                    showCancelSO: true,
                                                                                                    data_acc: el.data_acc.map((e) => e.orderac_id),
                                                                                                    supplier_id: el.supplier_id,
                                                                                               })
                                                                                          }
                                                                                     >
                                                                                          ยกเลิก
                                                                                     </Button>
                                                                                )}
                                                                           </Col>
                                                                           <Col xs={3}>
                                                                                {this.state.loading_btn ? (
                                                                                     <Button
                                                                                          variant="outline-primary"
                                                                                          disabled
                                                                                          style={{ backgroundColor: "#fff" }}
                                                                                     >
                                                                                          <Spinner
                                                                                               as="span"
                                                                                               animation="grow"
                                                                                               size="sm"
                                                                                               role="status"
                                                                                               aria-hidden="true"
                                                                                          />
                                                                                          Loading...
                                                                                     </Button>
                                                                                ) : (
                                                                                     <Button
                                                                                          disabled={
                                                                                               el.data_acc.some((e) => e.send_mail === 0) ||
                                                                                               el.data_acc.some((e) => e.isInstalled !== 0)
                                                                                          }
                                                                                          onClick={() =>
                                                                                               this.setState(
                                                                                                    {
                                                                                                         supplier_id: el.supplier_id,
                                                                                                         supplier_mail: el.supplier_mail,
                                                                                                    },
                                                                                                    () => this.sendEmail2()
                                                                                               )
                                                                                          }
                                                                                     >
                                                                                          ส่ง E-mail
                                                                                     </Button>
                                                                                )}
                                                                           </Col>
                                                                           <Col xs={3}>
                                                                                {this.state.loading_btn ? (
                                                                                     <Button
                                                                                          variant="outline-primary"
                                                                                          disabled
                                                                                          style={{ backgroundColor: "#fff" }}
                                                                                     >
                                                                                          <Spinner
                                                                                               as="span"
                                                                                               animation="grow"
                                                                                               size="sm"
                                                                                               role="status"
                                                                                               aria-hidden="true"
                                                                                          />
                                                                                          Loading...
                                                                                     </Button>
                                                                                ) : (
                                                                                     <Button
                                                                                          disabled={el.data_acc.some((e) => e.send_mail === 0)}
                                                                                          style={{ background: "green" }}
                                                                                          onClick={() =>
                                                                                               this.setState(
                                                                                                    {
                                                                                                         supplier_id: el.supplier_id,
                                                                                                    },
                                                                                                    () => this.downloadPdf()
                                                                                               )
                                                                                          }
                                                                                     >
                                                                                          พิมพ์ใบสั่งซื้อ
                                                                                     </Button>
                                                                                )}
                                                                           </Col>
                                                                           <Col xs={3}>
                                                                                {this.state.loading_btn ? (
                                                                                     <Button
                                                                                          variant="outline-primary"
                                                                                          disabled
                                                                                          style={{ backgroundColor: "#fff" }}
                                                                                     >
                                                                                          <Spinner
                                                                                               as="span"
                                                                                               animation="grow"
                                                                                               size="sm"
                                                                                               role="status"
                                                                                               aria-hidden="true"
                                                                                          />
                                                                                          Loading...
                                                                                     </Button>
                                                                                ) : (
                                                                                     <Button
                                                                                          disabled={
                                                                                               el.data_acc.some((e) => e.send_mail === 0) ||
                                                                                               el.data_acc.some((e) => e.isInstalled !== 0)
                                                                                          }
                                                                                          onClick={() =>
                                                                                               this.setState({
                                                                                                    showModalInstall: !this.state.showModalInstall,
                                                                                                    data_item: el.data_acc,
                                                                                                    supplier_name: el.supplier_name,
                                                                                               })
                                                                                          }
                                                                                     >
                                                                                          บันทึกติดตั้ง
                                                                                     </Button>
                                                                                )}
                                                                           </Col>
                                                                      </div>
                                                                 )}
                                                            </div>
                                                       ))}
                                                  </div>
                                                  {/* <Table
                                                            type="summary-custom-padding"
                                                            column={table.column}
                                                            data={table.data}
                                                            keyField={table.keyField}
                                                            selectRow={selectRow}
                                                       /> */}
                                                  {/* {addBtn && (
                                                            <div className="button-group __submit row">
                                                                 <Col xs={6}>
                                                                      <Button
                                                                           disabled={!order.ac.find((el) => el.send_mail === 0)}
                                                                           onClick={() => this.setState({ showmodal_email_sup: true })}
                                                                      >
                                                                           สั่งซื้ออุปกรณ์
                                                                      </Button>
                                                                 </Col>
                                                                 <Col xs={6}>
                                                                      <Button
                                                                           onClick={() =>
                                                                                this.setState({ showModalInstall: !this.state.showModalInstall })
                                                                           }
                                                                      >
                                                                           บันทึกการติดตั้ง
                                                                      </Button>
                                                                 </Col>
                                                            </div>
                                                       )} */}
                                                  {/* {editBtn && (
                                                       <LabelBar
                                                            type={"title"}
                                                            // disabled={true}
                                                            title={"รายการ Car Accessories"}
                                                            disabled={order.acept_status !== "จับคู่รถแล้ว" ? true : false}
                                                            link={"แก้ไขอุปกรณ์"}
                                                            onClickLink={() =>
                                                                 this.props.history.push({
                                                                      pathname: "/admin/order/car/detail/edit/",
                                                                      state: { order_id: order_id, type: "money" },
                                                                 })
                                                            }
                                                       />
                                                  )} */}

                                                  {/* {order?.order_mail_acc === 0 ? (
                                                       <>
                                                            <div className="content" id="table-content" style={{ width: "100%", padding: " 0 12px" }}>
                                                                 <Table
                                                                      type="summary-custom-padding"
                                                                      column={table_pending.column}
                                                                      data={table_pending.data}
                                                                      keyField={table_pending.keyField}
                                                                 />
                                                            </div>

                                                            <Row>
                                                                 <Col md={5}></Col>
                                                                 <Col>
                                                                      <Button onClick={this.onClickSetup}>สั่งซื้ออุปกรณ์</Button>
                                                                 </Col>
                                                                 <Col md={5}></Col>
                                                            </Row>
                                                       </>
                                                  ) : ( */}
                                                  <>
                                                       <ModalPanel
                                                            show={this.state.showModalInstall}
                                                            title={"ต้องการบันทึกการติดตั้งหรือไม่"}
                                                            type={"default"}
                                                            onHide={() => this.setState({ showModalInstall: !this.state.showModalInstall })}
                                                            button={[
                                                                 { onClick: () => this.onClickInstall(), name: "ตกลง", variant: "primary" },
                                                                 {
                                                                      onClick: () =>
                                                                           this.setState({ showModalInstall: !this.state.showModalInstall }),
                                                                      name: "ยกเลิก",
                                                                      variant: "outline-primary",
                                                                 },
                                                            ]}
                                                       />
                                                       <ModalPanel
                                                            show={this.state.showmodal_email_sup}
                                                            title={"ต้องการสั่งซื้ออุปกรณ์หรือไม่"}
                                                            type={"default"}
                                                            onHide={() => this.setState({ showmodal_email_sup: !this.state.showmodal_email_sup })}
                                                            button={[
                                                                 { onClick: () => this.onClickSendMail(), name: "ตกลง", variant: "primary" },
                                                                 {
                                                                      onClick: () =>
                                                                           this.setState({ showmodal_email_sup: !this.state.showmodal_email_sup }),
                                                                      name: "ยกเลิก",
                                                                      variant: "outline-primary",
                                                                 },
                                                            ]}
                                                       />
                                                       <ModalPanel
                                                            show={this.state.showCancelSO}
                                                            title={"ต้องการยกเลิกหรือไม่"}
                                                            type={"default"}
                                                            onHide={() => this.setState({ showCancelSO: !this.state.showCancelSO })}
                                                            button={[
                                                                 { onClick: () => this.onCancelSO(), name: "ตกลง", variant: "primary" },
                                                                 {
                                                                      onClick: () => this.setState({ showCancelSO: !this.state.showCancelSO }),
                                                                      name: "ยกเลิก",
                                                                      variant: "outline-primary",
                                                                 },
                                                            ]}
                                                       />
                                                       {/* <Row>
                                                                 <Col md={5}></Col>
                                                                 <Col>
                                                                      <Button onClick={this.onClickSetup}>บันทึกการติดตั้ง</Button>
                                                                 </Col>
                                                                 <Col md={5}></Col>
                                                            </Row> */}
                                                  </>
                                                  {/* )} */}

                                                  {/* <ModalPanel
                                                       type={"input"}
                                                       show={this.state.showmodal_email_sup}
                                                       onHide={() => this.setState({ showmodal_email_sup: false })}
                                                       button={[{ onClick: () => this.sendEmail(), name: "ตกลง", variant: "primary" }]}
                                                       body={this.modalEmailSupplier()}
                                                  /> */}
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"อนุมัติการติดตั้ง"} />
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "ผู้อนุมัติ : ",
                                                                 detail: order?.user_acc_install_name || "-",
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  <Row>
                                                       <LabelBar
                                                            type={"textheader"}
                                                            title={{
                                                                 name: "วันที่อนุมัติ : ",
                                                                 detail: order?.acc_install_date ? dateFormatter(order.acc_install_date) : "-",
                                                            }}
                                                            md={6}
                                                       />
                                                  </Row>
                                                  {/* <div className="-padding"> */}
                                                  <InputPanel
                                                       title={"หมายเหตุ"}
                                                       type={"textarea"}
                                                       name={"ac_install_note"}
                                                       placeholder={order?.ac_install_note}
                                                       value={order?.ac_install_note}
                                                       handleChange={(e) => this.handleChangeNote(e)}
                                                       rows={6}
                                                  />
                                                  {/* </div> */}
                                                  {/* <Row>
                                                       <Col md={5}></Col>
                                                       <Col>
                                                            <Button disabled={!this.state.btn_approve} onClick={this.onClickApprove}>
                                                                 ติดตั้งเสร็จแล้ว
                                                            </Button>
                                                       </Col>
                                                       <Col md={5}></Col>
                                                  </Row> */}
                                                  {addBtn && (
                                                       <div className="button-group __submit row">
                                                            {/* <Col xs={6}>
                                                                 <Button variant={"danger"} onClick={() => alert("ยกเลิกการแจ้งติดตั้งอุปกรณ์")}>
                                                                      ยกเลิกการแจ้งติดตั้งอุปกรณ์
                                                                 </Button>
                                                            </Col> */}
                                                            <Col xs={6}>
                                                                 {order?.ac_install === "รอการติดตั้ง" ? (
                                                                      <>
                                                                           {this.state.loading_btn ? (
                                                                                <Button
                                                                                     variant="outline-primary"
                                                                                     disabled
                                                                                     style={{ backgroundColor: "#fff" }}
                                                                                >
                                                                                     <Spinner
                                                                                          as="span"
                                                                                          animation="grow"
                                                                                          size="sm"
                                                                                          role="status"
                                                                                          aria-hidden="true"
                                                                                     />
                                                                                     Loading...
                                                                                </Button>
                                                                           ) : (
                                                                                <Button
                                                                                     // disabled={!this.state.btn_approve}
                                                                                     onClick={() => this.setState({ showModalApprove: true })}
                                                                                >
                                                                                     {order?.ac.find((el) => el.isInstalled === 0)
                                                                                          ? "ส่งมอบรถก่อน"
                                                                                          : "ส่งมอบรถ"}
                                                                                </Button>
                                                                           )}
                                                                      </>
                                                                 ) : (
                                                                      <>
                                                                           {this.state.loading_btn ? (
                                                                                <Button
                                                                                     variant="outline-primary"
                                                                                     disabled
                                                                                     style={{ backgroundColor: "#fff" }}
                                                                                >
                                                                                     <Spinner
                                                                                          as="span"
                                                                                          animation="grow"
                                                                                          size="sm"
                                                                                          role="status"
                                                                                          aria-hidden="true"
                                                                                     />
                                                                                     Loading...
                                                                                </Button>
                                                                           ) : (
                                                                                <Button
                                                                                     // disabled={!this.state.btn_approve}
                                                                                     onClick={() => this.setState({ showModalApproveNote: true })}
                                                                                >
                                                                                     บันทึกหมายเหตุ
                                                                                </Button>
                                                                           )}
                                                                      </>
                                                                 )}
                                                            </Col>
                                                            <ModalPanel
                                                                 show={this.state.showModalApprove}
                                                                 title={"ดำเนินการต่อหรือไม่"}
                                                                 type={"default"}
                                                                 onHide={() => this.setState({ showModalApprove: !this.state.showModalApprove })}
                                                                 button={[
                                                                      { onClick: () => this.onClickApprove(), name: "ตกลง", variant: "primary" },
                                                                      {
                                                                           onClick: () =>
                                                                                this.setState({ showModalApprove: !this.state.showModalApprove }),
                                                                           name: "ยกเลิก",
                                                                           variant: "outline-primary",
                                                                      },
                                                                 ]}
                                                            />
                                                            <ModalPanel
                                                                 show={this.state.showModalApproveNote}
                                                                 title={"ดำเนินการต่อหรือไม่"}
                                                                 type={"default"}
                                                                 onHide={() =>
                                                                      this.setState({ showModalApproveNote: !this.state.showModalApproveNote })
                                                                 }
                                                                 button={[
                                                                      { onClick: () => this.onClickSaveNote(), name: "ตกลง", variant: "primary" },
                                                                      {
                                                                           onClick: () =>
                                                                                this.setState({
                                                                                     showModalApproveNote: !this.state.showModalApproveNote,
                                                                                }),
                                                                           name: "ยกเลิก",
                                                                           variant: "outline-primary",
                                                                      },
                                                                 ]}
                                                            />
                                                            {/* <Col xs={6}>
                                                                 <Button onClick={() => alert("ส่งมอบรถก่อน")}>
                                                                      ส่งมอบรถก่อน
                                                                 </Button>
                                                            </Col> */}
                                                       </div>
                                                  )}
                                             </div>
                                        </div>
                                   </div>
                                   {!(
                                        order?.acept_status === "ชำระเงินแล้ว" ||
                                        order?.acept_status === "ส่งมอบรถแล้ว" ||
                                        order?.acept_status === "ปิดการขาย"
                                   ) &&
                                        editApprove && (
                                             <div className="input-field">
                                                  <div className="-body">
                                                       <div className="content">
                                                            <LabelBar type={"title"} title={"ยกเลิก"} />
                                                            {/* {!order?.order_mail_acc ? (
                                                       <Field
                                                            title={"รายการที่จะยกเลิก"}
                                                            type={"select"}
                                                            option={[{ value: "ยกเลิกการยืนยันการจับคู่รถ", name: "ยกเลิกการยืนยันการจับคู่รถ" }]}
                                                            name={"cancel_select_type"}
                                                            handleChangeText={(e) => this.handleChangeSelect(e)}
                                                            value={cancel?.cancel_select_type}
                                                            md={6}
                                                       />
                                                  ) : ( */}
                                                            {/* <Field
                                                                 title={"รายการที่จะยกเลิก"}
                                                                 type={"select"}
                                                                 option={
                                                                      !this.state.btn_approve
                                                                           ? [
                                                                                  // {
                                                                                  //      value: "ยกเลิกการแจ้งติดตั้งอุปกรณ์",
                                                                                  //      name: "ยกเลิกการแจ้งติดตั้งอุปกรณ์",
                                                                                  // },
                                                                                  //   {
                                                                                  //        value: "ยกเลิกการยืนยันการจับคู่รถ",
                                                                                  //        name: "ยกเลิกการยืนยันการจับคู่รถ",
                                                                                  //   },
                                                                                  {
                                                                                       value: "ยกเลิกการติดตั้งอุปกรณ์",
                                                                                       name: "ยกเลิกการติดตั้งอุปกรณ์",
                                                                                  },
                                                                             ]
                                                                           : [
                                                                                  //   {
                                                                                  //        value: "ยกเลิกการยืนยันการจับคู่รถ",
                                                                                  //        name: "ยกเลิกการยืนยันการจับคู่รถ",
                                                                                  //   },
                                                                                  {
                                                                                       value: "ยกเลิกการติดตั้งอุปกรณ์",
                                                                                       name: "ยกเลิกการติดตั้งอุปกรณ์",
                                                                                  },
                                                                             ]
                                                                 }
                                                                 name={"cancel_select_type"}
                                                                 handleChangeText={(e) => this.handleChangeSelect(e)}
                                                                 value={cancel?.cancel_select_type}
                                                                 md={6}
                                                            /> */}
                                                            {/* )} */}

                                                            <div className="-padding">
                                                                 <InputPanel
                                                                      title={"หมายเหตุ"}
                                                                      type={"textarea"}
                                                                      name={"cancel_note"}
                                                                      // placeholder={cancel?.cancel_note}
                                                                      value={cancel?.cancel_note}
                                                                      handleChange={(e) => this.handleChangeTextCancel(e)}
                                                                      rows={3}
                                                                 />
                                                            </div>
                                                            <div className="button-group __submit row">
                                                                 <Col xs={6}>
                                                                      {this.state.loading_btn ? (
                                                                           <Button
                                                                                variant="outline-primary"
                                                                                disabled
                                                                                style={{ backgroundColor: "#fff" }}
                                                                           >
                                                                                <Spinner
                                                                                     as="span"
                                                                                     animation="grow"
                                                                                     size="sm"
                                                                                     role="status"
                                                                                     aria-hidden="true"
                                                                                />
                                                                                Loading...
                                                                           </Button>
                                                                      ) : (
                                                                           <Button
                                                                                variant={"danger"}
                                                                                onClick={() => {
                                                                                     let { cancel } = this.state;
                                                                                     if (cancel.cancel_note.length > 0) {
                                                                                          this.setState({
                                                                                               showModalCancel: !this.state.showModalCancel,
                                                                                          });
                                                                                     } else {
                                                                                          swal({
                                                                                               icon: "warning",
                                                                                               title: "แจ้งเตือน",
                                                                                               text: "กรุณากรอกหมายเหตุการยกเลิก",
                                                                                          });
                                                                                     }
                                                                                }}
                                                                           >
                                                                                ยกเลิกการติดตั้งอุปกรณ์
                                                                           </Button>
                                                                      )}
                                                                 </Col>
                                                            </div>
                                                            <ModalPanel
                                                                 show={this.state.showModalCancel}
                                                                 title={"ต้องการยกเลิกหรือไม่"}
                                                                 type={"default"}
                                                                 onHide={() => this.setState({ showModalCancel: !this.state.showModalCancel })}
                                                                 button={[
                                                                      { onClick: () => this.onCancel(), name: "ตกลง", variant: "primary" },
                                                                      {
                                                                           onClick: () =>
                                                                                this.setState({ showModalCancel: !this.state.showModalCancel }),
                                                                           name: "ยกเลิก",
                                                                           variant: "outline-primary",
                                                                      },
                                                                 ]}
                                                            />
                                                            <ModalPanel
                                                                 type={"input"}
                                                                 show={this.state.modal_approve}
                                                                 onHide={() => this.setState({ modal_approve: false })}
                                                                 button={[
                                                                      {
                                                                           onClick: () => this.setState({ modal_approve: false }),
                                                                           variant: "primary",
                                                                           name: "ยืนยันการติดตั้งอุปกรณ์",
                                                                      },
                                                                 ]}
                                                                 btn_xs={8}
                                                                 body={this.modalApprove()}
                                                            />

                                                            <ModalPanel
                                                                 type={"input"}
                                                                 show={this.state.modal_vendor}
                                                                 onHide={() => this.setState({ modal_vendor: false })}
                                                                 button={[
                                                                      {
                                                                           onClick: this.changeVender,
                                                                           variant: "primary",
                                                                           name: "ยืนยัน",
                                                                      },
                                                                 ]}
                                                                 btn_xs={8}
                                                                 body={this.modalVendor()}
                                                            />
                                                       </div>
                                                  </div>
                                             </div>
                                        )}
                              </div>
                         </div>
                    );
               case "ติดตั้งเรียบร้อยแล้ว-cancel":
                    return (
                         <div className="panel">
                              <Breadcrumbs
                                   title={[
                                        {
                                             title: `รายการใบจองที่พร้อมติดตั้ง`,
                                             onClick: () => window.location.assign("/admin/install/list"),
                                        },
                                        {
                                             title: `รายงานใบจอง (${order?.order_code_id || ""})`,
                                             onClick: () => window.location.assign(`/admin/order/car/detail${order_id}`),
                                        },
                                   ]}
                                   active={2}
                                   button={""}
                              />
                              <div className="content __input-panel">
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"ข้อมูลใบจอง"} />
                                                  <div className="-padding">
                                                       <div className="text-underline">
                                                            <Row>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "เลขที่ใบจอง : ",
                                                                           detail: `${order?.order_code_id || ""}`,
                                                                      }}
                                                                      md={6}
                                                                      onClickLabelBar={() =>
                                                                           window.location.assign(`/admin/order/car/detail${order_id}`)
                                                                      }
                                                                      textHeader_style={"underline"}
                                                                 />
                                                            </Row>
                                                       </div>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "โชว์รูม : ",
                                                                      detail: order?.branch_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "พนักงานขาย : ",
                                                                      detail: order?.saler_name_string,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ชื่อ-นามสกุล ผู้สั่งจอง : ",
                                                                      detail: order?.userinfo_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขประจำตัว : ",
                                                                      detail: order?.userinfo_idcard,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "หมายเลขโทรศัพท์ : ",
                                                                      detail: order?.userinfo_tel,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ที่อยู่ : ",
                                                                      detail: `${order?.userinfo_address}  ตำบล/แขวง ${order?.userinfo_sub_district} อำเภอ/เขต ${order?.userinfo_district} จังหวัด ${order?.userinfo_province} ${order?.userinfo_zipcode}`,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายละเอียดรถยนต์"} />
                                                  <div className="-padding">
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Series : ",
                                                                      detail: order?.series_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Model : ",
                                                                      detail: order?.model_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "Color : ",
                                                                      detail: order?.color_name,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขตัวถัง : ",
                                                                      detail: order?.car_tank,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "เลขเครื่องยนต์ : ",
                                                                      detail: order?.car_engine,
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                  </div>
                                                  <LabelBar type={"title"} title={"รายการ Car Accessories"} />
                                                  <div className="content" id="table-content" style={{ width: "100%", padding: " 0 12px" }}>
                                                       {/* <Table type="summary-custom-padding" column={table2.column} data={table2.data} keyField={table2.keyField} selectRow={selectRow} /> */}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div className="input-field">
                                        <div className="-body">
                                             <div className="content">
                                                  <LabelBar type={"title"} title={"อนุมัติการติดตั้ง"} />
                                                  <div className="-padding">
                                                       {this.state.editNote ? (
                                                            <>
                                                                 <InputPanel
                                                                      title={"หมายเหตุ"}
                                                                      type={"textarea"}
                                                                      name={"ac_install_note"}
                                                                      placeholder={order?.ac_install_note}
                                                                      value={order?.ac_install_note}
                                                                      handleChange={(e) => this.handleChangeNote(e)}
                                                                      rows={3}
                                                                 />
                                                                 {addBtn && (
                                                                      <div className="button-group __submit row">
                                                                           <Col md={6} xs={12}>
                                                                                <Button
                                                                                     variant={"primary"}
                                                                                     onClick={() => this.setState({ editNote: false })}
                                                                                >
                                                                                     บันทึก
                                                                                </Button>
                                                                           </Col>
                                                                      </div>
                                                                 )}
                                                            </>
                                                       ) : (
                                                            <Row>
                                                                 <LabelBar
                                                                      type={"textheader"}
                                                                      title={{
                                                                           name: "หมายเหตุ : ",
                                                                           detail: order?.ac_install_note || "-",
                                                                      }}
                                                                      md={6}
                                                                 />
                                                                 {editBtn && (
                                                                      <a
                                                                           style={{ color: "#1d419b" }}
                                                                           className="underline"
                                                                           onClick={() => this.setState({ editNote: true })}
                                                                      >
                                                                           แก้ไขหมายเหตุ
                                                                      </a>
                                                                 )}
                                                            </Row>
                                                       )}

                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "ผู้อนุมัติ : ",
                                                                      detail: order?.user_acc_install_name || "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       <Row>
                                                            <LabelBar
                                                                 type={"textheader"}
                                                                 title={{
                                                                      name: "วันที่อนุมัติ : ",
                                                                      detail: order?.acc_install_date ? dateFormatter(order.acc_install_date) : "-",
                                                                 }}
                                                                 md={6}
                                                            />
                                                       </Row>
                                                       {/* <Breadcrumbs under={true} type={"success"} title={"ติดตั้งอุปกรณ์เรียบร้อยแล้ว"} />
                                                       <div style={{ height: "80px" }}></div> */}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   {!(
                                        order?.acept_status === "ชำระเงินแล้ว" ||
                                        order?.acept_status === "ส่งมอบรถแล้ว" ||
                                        order?.acept_status === "ปิดการขาย"
                                   ) &&
                                        editApprove && (
                                             <div className="input-field">
                                                  <div className="-body">
                                                       <div className="content">
                                                            <LabelBar type={"title"} title={"ยกเลิก"} />
                                                            <Field
                                                                 title={"รายการที่จะยกเลิก"}
                                                                 type={"select"}
                                                                 option={[
                                                                      // { value: "ยกเลิกการยืนยันการจับคู่รถ", name: "ยกเลิกการยืนยันการจับคู่รถ" },
                                                                      // { value: "ยกเลิกการแจ้งติดตั้งอุปกรณ์", name: "ยกเลิกการแจ้งติดตั้งอุปกรณ์" },
                                                                      { value: "ยกเลิกการติดตั้งอุปกรณ์", name: "ยกเลิกการติดตั้งอุปกรณ์" },
                                                                 ]}
                                                                 name={"cancel_select_type"}
                                                                 handleChangeText={(e) => this.handleChangeSelect(e)}
                                                                 value={cancel?.cancel_select_type}
                                                                 md={6}
                                                            />
                                                            <div className="-padding">
                                                                 <InputPanel
                                                                      title={"หมายเหตุ"}
                                                                      type={"textarea"}
                                                                      name={"cancel_note"}
                                                                      // placeholder={cancel?.cancel_note}
                                                                      value={cancel?.cancel_note}
                                                                      handleChange={(e) => this.handleChangeTextCancel(e)}
                                                                      rows={3}
                                                                 />
                                                            </div>
                                                            <div className="button-group __submit row">
                                                                 <Col xs={6}>
                                                                      <Button
                                                                           variant={"danger"}
                                                                           onClick={() =>
                                                                                this.setState({ showModalCancel: !this.state.showModalCancel })
                                                                           }
                                                                      >
                                                                           ยกเลิกการติดตั้งอุปกรณ์
                                                                      </Button>
                                                                 </Col>
                                                                 <ModalPanel
                                                                      show={this.state.showModalCancel}
                                                                      title={"ต้องการยกเลิกหรือไม่"}
                                                                      type={"default"}
                                                                      onHide={() => this.setState({ showModalCancel: !this.state.showModalCancel })}
                                                                      button={[
                                                                           { onClick: () => this.onCancel(), name: "ตกลง", variant: "primary" },
                                                                           {
                                                                                onClick: () =>
                                                                                     this.setState({ showModalCancel: !this.state.showModalCancel }),
                                                                                name: "ยกเลิก",
                                                                                variant: "outline-primary",
                                                                           },
                                                                      ]}
                                                                 />
                                                                 <Breadcrumbs under={true} type={"success"} title={"ติดตั้งอุปกรณ์เรียบร้อยแล้ว"} />
                                                                 <div style={{ height: "80px" }}></div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        )}
                              </div>
                         </div>
                    );
               default:
                    return null;
          }
     }
}
